<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col cols="12">
            <v-row no-gutters class="pt-8 align-center justify-center">
              <v-col cols="12" v-for="(card, index) in cards" :key="index" sm="5" md="4" v-if="checkIsAllowed(card.id)">
                <v-card @click="openFunctionality(card.id)" class="mx-8 mb-8">
                  <v-card-title>{{ card.title }}</v-card-title>
                  <v-card-text>{{ card.content }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import utils from '@/utils/utils';
export default {
  name: "Home",
  data() {
    return {
      utils: utils,
      cards: [
        {
          id: 0,
          title: "Jobs Management",
          content: "Management of existing jobs.",
        },
        {
          id: 1,
          title: "Contexts Management",
          content: "Management of existing contexts.",
        },
        {
          id: 2,
          title: "Competencies Management",
          content: "Management of existing competencies.",
        },
        {
          id: 3,
          title: "Positions Management",
          content: "Management of existing positions.",
        },
        {
          id: 4,
          title: "Interview Types Management",
          content: "Management of existing interview types.",
        },
        {
          id: 6,
          title: "Components Management",
          content: "Management of existing components.",
        },
        {
          id: 7,
          title: "Interview Analysis Types Management",
          content: "Management of existing interview analysis types.",
        },

        {
          id: 9,
          title: "Virtual Profiler Management",
          content: "Management of existing virtual profilers.",
        },

        {
          id: 12,
          title: "Candidates Management",
          content: "Management of existing candidates.",
        },
        {
          id: 13,
          title: "Users Management",
          content: "Management of existing users.",
        },
        {
          id: 14,
          title: "AI Dialogue",
          content: "Mock view of AI Dialogue interaction.",
        },
      ],
      notAllowed: [12]
    };
  },
  components: {},
  created() { },
  mounted() { },
  beforeUnmount() { },
  methods: {
    checkIsAllowed(id) {
      return utils.config.preProd || !this.notAllowed.includes(id);
    },
    openFunctionality(id) {
      if (id == 0) {
        this.openJobsManagement();
      } else if (id == 1) {
        this.openContextsManagement();
      } else if (id == 2) {
        this.openCompetenciesManagement();
      } else if (id == 3) {
        this.openPositionsManagement();
      } else if (id == 4) {
        this.openInterviewTypesManagement();

      } else if (id == 6) {
        this.openComponentsManagement();
      } else if (id == 7) {
        this.openInterviewAnalysisTypesManagement();
      } else if (id == 9) {
        this.openVirtualProfilersManagement();
      } else if (id == 12 && this.checkIsAllowed(id)) {
        this.openCandidatesManagement();
      } else if (id == 13) {
        this.openUsersManagement();
      } else if (id == 14) {
        this.openMockView();
      }
    },
    openJobsManagement() {
      this.$router.push("/jobs");
    },
    openContextsManagement() {
      this.$router.push("/contexts");
    },
    openCompetenciesManagement() {
      this.$router.push("/competencies");
    },
    openPositionsManagement() {
      this.$router.push("/positions");
    },
    openInterviewTypesManagement() {
      this.$router.push("/interview-types");
    },

    openComponentsManagement() {
      this.$router.push("/components");
    },
    openInterviewAnalysisTypesManagement() {
      this.$router.push("/interview-analysis-types");
    },

    openVirtualProfilersManagement() {
      this.$router.push("/virtual-profilers");
    },

    openCandidatesManagement() {
      this.$router.push("/candidates");
    },
    openUsersManagement() {
      this.$router.push("/users");
    },
    openMockView() {
      this.$router.push("/ai-dialogue");
    },
  },
};
</script>
