<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Components</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateComponent"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create component
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateComponent"
                  outlined
                  :color="successfullCreateComponent ? 'success' : 'error'"
                  :icon="
                    successfullCreateComponent
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateComponent }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteComponent"
                  outlined
                  :color="successfullDeleteComponent ? 'success' : 'error'"
                  :icon="
                    successfullDeleteComponent
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteComponent }}
                </v-alert>
                <v-alert
                  v-if="messageEditComponent"
                  outlined
                  :color="successfullEditComponent ? 'success' : 'error'"
                  :icon="
                    successfullEditComponent ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditComponent }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="components"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.itemsPerPage"
                  :page.sync="pagination.page"
                  @update:page="handlePageChange"
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openEditComponent(item)">
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit component</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteComponent(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete component</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageComponents"
              outlined
              :color="successfullComponents ? 'success' : 'error'"
              :icon="successfullComponents ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageComponents }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageComponentTypes"
              outlined
              :color="successfullComponentTypes ? 'success' : 'error'"
              :icon="
                successfullComponentTypes ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageComponentTypes }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createComponentDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createComponentForm" @submit.prevent="createComponent">
          <v-card-title>
            <h5>Create Component</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="component.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    label="Type *"
                    :items="componentTypes"
                    v-model="component.type"
                    item-text="name"
                    item-value="id"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0" v-if="component.type === 'VIDEO'">
                  <v-text-field
                    label="URL *"
                    v-model="component.videoUrl"
                    :rules="urlRules"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  class="pt-0"
                  v-if="component.type === 'TEST' || component.type === 'LIST'"
                >
                  <v-row>
                    <v-col cols="8" class="pt-0">
                      <h6 class="details-subtitles">Steps</h6>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-btn
                        text
                        class="float-right"
                        color="primary"
                        @click="addStep(component)"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="component.type === 'TEST' || component.type === 'LIST'"
                >
                  <v-data-table
                    :headers="headersSteps"
                    :items="component.steps"
                  >
                    <template v-slot:[`item.order`]="{ item }">
                      {{ item.order + 1 }}
                    </template>
                    <template v-slot:[`item.content`]="{ item }">
                      <v-text-field
                        v-model="item.content"
                        :rules="rules"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon size="small" @click="deleteStep(component, item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateComponentInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullCreateComponentInside ? 'success' : 'error'"
                :icon="
                  successfullCreateComponentInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateComponentInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateComponent" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!creatingComponent">
              Create
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editComponentDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editComponentForm" @submit.prevent="editComponent">
          <v-card-title>
            <h5>Update Component</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    label="Type *"
                    :items="componentTypes"
                    v-model="itemToEdit.type"
                    item-text="name"
                    item-value="id"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="itemToEdit.type === 'VIDEO'"
                >
                  <v-text-field
                    label="URL *"
                    v-model="itemToEdit.videoUrl"
                    :rules="urlRules"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  class="pt-0"
                  v-if="
                    itemToEdit.type === 'TEST' || itemToEdit.type === 'LIST'
                  "
                >
                  <v-row>
                    <v-col cols="8" class="pt-0">
                      <h6 class="details-subtitles">Steps</h6>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-btn
                        text
                        class="float-right"
                        color="primary"
                        @click="addStepEdit(itemToEdit)"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="
                    itemToEdit.type === 'TEST' || itemToEdit.type === 'LIST'
                  "
                >
                  <v-data-table
                    :headers="headersSteps"
                    :items="itemToEdit.componentSteps"
                  >
                    <template v-slot:[`item.order`]="{ item }">
                      {{ item.order + 1 }}
                    </template>
                    <template v-slot:[`item.content`]="{ item }">
                      <v-text-field
                        v-model="item.content"
                        :rules="rules"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        size="small"
                        @click="deleteStepEdit(itemToEdit, item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-alert
                v-if="messageEditComponentInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullEditComponentInside ? 'success' : 'error'"
                :icon="
                  successfullEditComponentInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditComponentInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editComponentDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!editingComponent">
              Update
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteComponentDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Component</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected component? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteComponentDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingComponent">
            <v-btn @click="deleteComponent" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deleteComponent" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsComponentDialog" max-width="800">
      <v-card>
        <v-form ref="detailsComponentForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Type:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.type }}</p>
                </v-col>
              </v-row>
              <v-row v-if="itemToShow.type === 'VIDEO'">
                <v-col cols="2">
                  <h6 class="details-subtitles">URL:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.videoUrl }}</p>
                </v-col>
              </v-row>
              <template
                v-if="itemToShow.type === 'TEST' || itemToShow.type === 'LIST'"
              >
                <v-row
                  v-if="
                    itemToShow.steps !== undefined &&
                    itemToShow.steps.length == 0
                  "
                >
                  <v-col cols="2">
                    <h6 class="details-subtitles">Steps:</h6>
                  </v-col>
                  <v-col cols="10">
                    <p class="mb-0">Component with no associated steps</p>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col cols="12" class="pb-0">
                    <h6 class="details-subtitles">Steps:</h6>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-data-table
                      :headers="headersDetails"
                      :items="itemToShow.componentSteps"
                      item-key="itemToShow.id"
                    >
                      <template v-slot:[`item.order`]="{ item }">
                        {{ item.order + 1 }}
                      </template></v-data-table
                    >
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsComponentDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ComponentsDataService from "@/services/ComponentsDataService";

export default {
  name: "ComponentsList",
  data() {
    return {
      components: [],
      successfullComponents: false,
      messageComponents: "",
      componentTypes: [],
      successfullComponentTypes: false,
      messageComponentTypes: "",
      loading: false,
      successfullCreateComponent: false,
      messageCreateComponent: "",
      successfullCreateComponentInside: false,
      messageCreateComponentInside: "",
      successfullEditComponent: false,
      messageEditComponent: "",
      successfullEditComponentInside: false,
      messageEditComponentInside: "",
      successfullDeleteComponent: false,
      messageDeleteComponent: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        // { text: "Type", value: "componentType.name" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalPages: 1,
        totalItems: 0,
      },
      component: {
        name: "",
        type: "",
        videoUrl: null,
        steps: [],
      },
      rules: [(v) => !!v || "Required field"],
      urlRules: [
        (v) => !!v || "Required field",
        (v) =>
          /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(
            v
          ) || "Invalid URL format",
      ],
      createComponentDialog: false,
      creatingComponent: false,
      editComponentDialog: false,
      editingComponent: false,
      deleteComponentDialog: false,
      deletingComponent: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      headersDetails: [
        { text: "Step", value: "order" },
        { text: "Content", value: "content" },
      ],
      showDetailsComponentDialog: false,
      headersSteps: [
        { text: "Step", value: "order" },
        { text: "Content", value: "content" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    "itemToEdit.type"(newType, oldType) {
      console.log("newType: ", newType);
      console.log("oldType: ", oldType);
      if (oldType !== undefined) {
        this.itemToEdit.componentSteps = [];
        this.itemToEdit.videoUrl = null;
      }
    },
  },
  async mounted() {
    await this.getAllComponentTypes();
    await this.getAllComponents(1);
  },
  methods: {
    getAllComponents(page) {
      this.loading = true;

      ComponentsDataService.findAll(page, this.pagination.itemsPerPage).then(
        (response) => {
          if (response.status == 200) {
            this.components = response.data.data;

            if (this.components.length !== 0) {
              this.pagination.page = page;
              this.pagination.totalPages = response.data.links.totalPages || 1;

              this.pagination.totalItems =
                response.data.links.totalItems ||
                this.pagination.totalPages * this.pagination.itemsPerPage;
            }
          } else {
            this.successfullComponents = false;
            this.messageComponents =
              "An error has occurred retrieving the list of components. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullComponents = false;
          this.messageComponents =
            "An error has occurred retrieving the list of components. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    handlePageChange(newPage) {
      console.log("handlePageChange: ", newPage);
      this.pagination.page = newPage;
      this.getAllComponents(newPage);
    },
    getAllComponentTypes() {
      this.loading = true;

      ComponentsDataService.findAllComponentTypes().then(
        (response) => {
          if (response.status == 200) {
            this.componentTypes = response.data.data.type;
            console.log("componentTypes: ", this.componentTypes);
            let tmpComponentTypes = [];
            this.componentTypes.forEach((type) => {
              tmpComponentTypes.push({ id: type, name: type });
            });

            this.componentTypes = tmpComponentTypes;
          } else {
            this.successfullComponentTypes = false;
            this.messageComponentTypes =
              "An error has occurred retrieving the list of component types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullComponentTypes = false;
          this.messageComponentTypes =
            "An error has occurred retrieving the list of component types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openCreateComponent() {
      this.createComponentDialog = true;
    },
    addStep(component) {
      console.log("component: ", component);
      component.steps.push({
        content: "",
        order: component.steps.length,
      });
    },
    deleteStep(component, step) {
      component.steps.splice(step.order, 1);

      for (let i = 0; i < component.steps.length; i++) {
        component.steps[i].order = i;
      }
    },
    createComponent() {
      this.successfullCreateComponent = true;
      this.messageCreateComponent = "";

      if (this.$refs.createComponentForm.validate()) {
        this.creatingComponent = true;

        if (this.component.componentTypeId !== null) {
          if (this.component.type === "VIDEO") {
            this.component.steps = [
              { content: this.component.videoUrl, order: 0 },
            ];
          }

          if (
            this.component.type === "TEST" ||
            this.component.type === "LIST"
          ) {
            if (this.component.steps.length === 0) {
              this.successfullCreateComponentInside = false;
              setTimeout(() => {
                this.messageCreateComponentInside =
                  "Please, add at least one step to the component.";
              }, 10);

              this.creatingComponent = false;
              return;
            } else {
              this.component.videoUrl = null;
            }
          }

          console.log("this.component: ", this.component);
          ComponentsDataService.create(this.component).then(
            (response) => {
              if (response.status == 201) {
                this.getAllComponents(1);

                this.messageCreateComponent = "";
                this.successfullCreateComponent = true;

                setTimeout(() => {
                  this.messageCreateComponent =
                    "Component successfully created.";
                }, 10);
              } else {
                this.messageCreateComponent = "";
                this.successfullCreateComponent = true;

                setTimeout(() => {
                  this.messageCreateComponent =
                    "An error has occurred creating the component. Please, try again later.";
                }, 10);
              }

              this.createComponentDialog = false;
              this.creatingComponent = false;
              this.component = {
                name: "",
                type: "",
                videoUrl: null,
                steps: [],
              };
            },
            (error) => {
              this.messageCreateComponentInside = "";
              this.messageCreateComponent = "";

              if (error.response.status === 409) {
                this.successfullCreateComponentInside = false;

                setTimeout(() => {
                  this.messageCreateComponentInside =
                    "Component already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullCreateComponent = false;

                setTimeout(() => {
                  this.messageCreateComponent =
                    "An error has occurred creating the component. Please, try again later.";
                }, 10);

                this.createComponentDialog = false;
                this.component = {
                  name: "",
                  type: "",
                  videoUrl: null,
                  steps: [],
                };
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.creatingComponent = false;
            }
          );
        } else {
          this.successfullCreateComponentInside = false;
          setTimeout(() => {
            this.messageCreateComponentInside =
              "Please, select a type for the component.";
          }, 10);

          this.creatingComponent = false;
        }
      }
    },
    cancelCreateComponent() {
      this.createComponentDialog = false;
      this.component = {
        name: "",
        type: "",
        videoUrl: null,
        steps: [],
      };
    },
    openEditComponent(component) {
      this.itemToEdit = { ...component };
      if (this.itemToEdit.type === "VIDEO") {
        this.itemToEdit.videoUrl = this.itemToEdit.componentSteps[0].content;
      } else {
        this.itemToEdit.componentSteps.forEach((step, index) => {
          step.order = index;
        });
      }
      this.editComponentDialog = true;
    },
    addStepEdit(component) {
      console.log("component: ", component);
      component.componentSteps.push({
        content: "",
        order: component.componentSteps.length,
      });
    },
    deleteStepEdit(component, step) {
      component.componentSteps.splice(step.order, 1);

      for (let i = 0; i < component.componentSteps.length; i++) {
        component.componentSteps[i].order = i;
      }
    },
    editComponent() {
      this.successfullEditComponent = true;

      if (this.$refs.editComponentForm.validate()) {
        this.editingComponent = true;

        if (this.itemToEdit.type === "VIDEO") {
          this.itemToEdit.componentSteps = [
            { content: this.itemToEdit.videoUrl, order: 0 },
          ];
        }

        if (this.itemToEdit.componentSteps.length === 0) {
          this.successfullEditComponentInside = false;
          setTimeout(() => {
            this.messageEditComponentInside =
              "Please, add at least one step to the component.";
          }, 10);

          this.editingComponent = false;
          return;
        } else {
          this.component.videoUrl = null;
        }

        this.itemToEdit.steps = this.itemToEdit.componentSteps;
        delete this.itemToEdit.componentSteps;

        ComponentsDataService.update(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllComponents(1);
              this.messageEditComponent = "";
              this.successfullEditComponent = true;

              setTimeout(() => {
                this.messageEditComponent = "Component successfully updated.";
              }, 10);
            } else {
              this.messageEditComponent = "";
              this.successfullEditComponent = true;

              setTimeout(() => {
                this.messageEditComponent =
                  "An error has occurred updating the component. Please, try again later.";
              }, 10);
            }

            this.editComponentDialog = false;
            this.editingComponent = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditComponentInside = "";
            this.messageEditComponent = "";

            if (error.response.status === 409) {
              this.successfullEditComponentInside = false;

              setTimeout(() => {
                this.messageEditComponentInside =
                  "Component already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditComponent = false;

              setTimeout(() => {
                this.messageEditComponent =
                  "An error has occurred updating the component. Please, try again later.";
              }, 10);

              this.editComponentDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingComponent = false;
          }
        );
      }
    },
    openDeleteComponent(component) {
      this.itemToDelete = { ...component };
      this.deleteComponentDialog = true;
    },
    deleteComponent() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingComponent = true;

        ComponentsDataService.delete(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllComponents(1);
              this.messageDeleteComponent = "";
              this.successfullDeleteComponent = true;

              setTimeout(() => {
                this.messageDeleteComponent = "Component successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteComponent = "";
              this.successfullDeleteComponent = false;

              setTimeout(() => {
                this.messageDeleteComponent =
                  "An error has occurred deleting the component. Please, try again later.";
              }, 10);
            }

            this.deleteComponentDialog = false;
            this.deletingComponent = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteComponent = "";
            this.successfullDeleteComponent = false;

            setTimeout(() => {
              this.messageDeleteComponent =
                "An error has occurred deleting the component. Please, try again later.";
            }, 10);

            this.deleteComponentDialog = false;
            this.itemToDelete = {};

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.deletingComponent = false;
          }
        );
      } else {
        this.messageDeleteComponent = "";
        this.successfullDeleteComponent = false;

        setTimeout(() => {
          this.messageDeleteComponent =
            "An error has occurred deleting the component. Please, try again later.";
        }, 10);

        this.deleteComponentDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(component) {
      this.itemToShow = { ...component };

      if (this.itemToShow.type === "VIDEO") {
        this.itemToShow.videoUrl = this.itemToShow.componentSteps[0].content;
      } else {
        this.itemToShow.componentSteps.forEach((step, index) => {
          step.order = index;
        });
      }
      this.showDetailsComponentDialog = true;
    },
  },
};
</script>
