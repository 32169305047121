import http from "../http-common";

const API_URL_PATH = "profilers/";
const API_URL_PATH_WITHOUT_BAR = "profilers";
// const API_URL_PATH_TYPES = "profiler-types/";
// const API_URL_PATH_ANIMATIONS = "profiler-animations/";
const API_URL_PATH_UTILS = "utils/";

class VirtualProfilersDataService {
  findAll(page, limit) {
    return http.get(
      API_URL_PATH_WITHOUT_BAR + "?page=" + page + "&limit=" + limit
    );
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(virtualProfiler) {
    return http.post(API_URL_PATH, virtualProfiler);
  }

  assign(id) {
    return http.post(API_URL_PATH + id);
  }

  unassign() {
    return http.patch(API_URL_PATH);
  }

  /* findAllVirtualProfilerTypes() {
    return http.get(API_URL_PATH_TYPES);
  }

  findOneVirtualProfilerType(id) {
    return http.get(API_URL_PATH_TYPES + id);
  }

  createVirtualProfilerType(virtualProfilerType) {
    return http.post(API_URL_PATH_TYPES, virtualProfilerType);
  }

  updateVirtualProfilerType(virtualProfilerType) {
    return http.put(
      API_URL_PATH_TYPES + virtualProfilerType.id,
      virtualProfilerType
    );
  }

  deleteVirtualProfilerType(id) {
    return http.delete(API_URL_PATH_TYPES + id);
  } */

  /* findAllVirtualProfilerAnimations() {
    return http.get(API_URL_PATH_ANIMATIONS);
  }

  findOneVirtualProfilerAnimation(id) {
    return http.get(API_URL_PATH_ANIMATIONS + id);
  }

  createVirtualProfilerAnimation(virtualProfilerAnimation, onUploadAnimation) {
    return http.post(API_URL_PATH_ANIMATIONS, virtualProfilerAnimation, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadAnimation,
    });
  }

  updateVirtualProfilerAnimation(
    virtualProfilerAnimation,
    id,
    onUploadAnimation
  ) {
    return http.put(API_URL_PATH_ANIMATIONS + id, virtualProfilerAnimation, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadAnimation,
    });
  }

  deleteVirtualProfilerAnimation(id) {
    return http.delete(API_URL_PATH_ANIMATIONS + id);
  } */

  findAllVirtualProfilerVoices() {
    return http.get(API_URL_PATH_UTILS + "voices");
  }

  findVirtualProfilerVoicesByService(service) {
    return http.get(API_URL_PATH_UTILS + "voices?type=" + service);
  }

  findFormOptions() {
    return http.get(API_URL_PATH_UTILS + "/form-profilers");
  }

  findProfilerModels() {
    return http.get(API_URL_PATH_UTILS + "/form-profilers-models");
  }
}

export default new VirtualProfilersDataService();
