import http from "../http-common";

const API_URL_PATH = "contexts/";
const API_URL_PATH_WITHOUT_BAR = "contexts";

class ContextsDataService {
  findAll(page, limit) {
    return http.get(
      API_URL_PATH_WITHOUT_BAR + "?page=" + page + "&limit=" + limit
    );
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(context) {
    return http.post(API_URL_PATH, context);
  }

  update(context) {
    return http.put(API_URL_PATH + context.id, context);
  }

  delete(id) {
    return http.delete(API_URL_PATH + id);
  }
}

export default new ContextsDataService();
