import { io } from "socket.io-client";
import Vue from "vue";
import utils from "@/utils/utils";

class SocketioService {
	emisor = new Vue();
	callRoom = "";

	setupSocketConnection(id, username, isInterviewer) {
		const socket = io(
			`${utils.api.protocol}://${utils.api.host}:${utils.api.port}`,
			{ withCredentials: true }
		);

		if (isInterviewer) this.sendInterviewerId(socket, id, username);
		else this.sendCandidateId(socket, id);

		socket.on("add-interviewer", (data) => {
			console.log("addInterviewer: ", data);
			Vue.prototype.$socketId = data.socketId;
		});

		socket.on("add-candidate", (data) => {
			console.log("addCandidate: ", data);
			Vue.prototype.$socketId = data.socketId;
		});

		socket.on("get-notifications", (data) => {
			this.emisor.$emit(
				"notificationsSended",
				data.map((msg) => JSON.parse(msg))
			);
		});

		socket.on("cv-completed", (data) => {
			this.emisor.$emit("notificationsActiveSended", JSON.parse(data.msg));
		});

		socket.on("cv-error", (data) => {
			this.emisor.$emit("notificationsActiveSended", JSON.parse(data.msg));
		});

		socket.on("dismiss-notification", (data) => {
			this.emisor.$emit("notificationDismissed", data.response);
		});

		socket.on("call-room", (data) => {
			console.log("call-room", data);
			this.emisor.$emit("callRoomResponse", data);
		});

		socket.on("call-open", (data) => {
			this.emisor.$emit("callOpenResponse", data);
		});

		socket.on("call-close", (data) => {
			this.emisor.$emit("callCloseResponse", data);
		});

		socket.on("call-user-join", (data) => {
			console.log("call-user-join", data);
			this.emisor.$emit("callUserJoinResponse", data);
		});

		socket.on("call-user-opt", (data) => {
			this.emisor.$emit("callUserOptResponse", data);
		});

		socket.on("chat-user-message", (data) => {
			this.emisor.$emit("callUserChatMsgResponse", data);
		});

		socket.on("call-user-leave", (data) => {
			this.emisor.$emit("callUserLeaveResponse", data);
		});

		socket.on("call-user-finished", (data) => {
			this.emisor.$emit("callUserFinishedResponse", data);
		});

		socket.on("call-messages", (data) => {
			console.log("call-messages", data);
			this.emisor.$emit("callMessagesResponse", data);
		});

		/* AI Dialogue */
		socket.on("authenticate-response", (data) => {
			console.log("authenticate-response", data);
			this.emisor.$emit("authenticateResponse", data);
		});

		socket.on("dialogue-conversation-response", (data) => {
			console.log("dialogue-conversation-response", data);
			this.emisor.$emit("dialogueConversationResponse", data);
		});

		socket.on("dialogue-close-response", (data) => {
			console.log("dialogue-close-response", data);
			// this.emisor.$emit("dialogueCloseResponse", data);
		});

		socket.on("dialogue-partial-msg", (data) => {
			console.log("dialogue-partial-msg", data);
			this.emisor.$emit("dialoguePartialMsgResponse", data);
		});

		socket.on("dialogue-complete-msg", (data) => {
			console.log("dialogue-complete-msg", data);
			this.emisor.$emit("dialogCompleteMsgResponse", data);
		});

		socket.on("dialogue-contexts-response", (data) => {
			console.log("dialogue-contexts-response", data);
			this.emisor.$emit("dialogContextsResponse", data);
		});

		return socket;
	}

	disconnect(socket) {
		if (socket) {
			socket.disconnect();
		}
	}

	sendInterviewerId(socket, interviewerId, interviewerUsername) {
		socket.emit("add-interviewer", {
			interviewer: { id: interviewerId, username: interviewerUsername },
		});
	}

	sendCandidateId(socket, candidateId) {
		socket.emit("add-candidate", { candidate: candidateId });
	}

	dismissNotification(socket, index) {
		socket.emit("dismiss-notification", { index: index });
	}

	createCallRoom(socket, callId) {
		socket.emit("call-room", { callId: callId });
	}

	joinCall(socket, peerId, camera, micro) {
		console.log("joinCall en el socket service", peerId, camera, micro);
		socket.emit("call-join", {
			peerId: peerId,
			video: camera,
			audio: micro,
			serializations: ["binary", "binary-utf8", "json"],
		});
	}

	leaveCall(socket, isCandidate) {
		socket.emit("call-leave");
		if (!isCandidate) {
			socket.emit("call-finished"); // AQUI SE ENVIA PARA ACABAR LA ENTREVISTA
		}

		return true;
	}

	callOptions(socket, camera, micro) {
		socket.emit("call-opt", { video: camera, audio: micro, hand: false });
	}

	chatMessage(socket, message, timeSended) {
		socket.emit("call-chat-msg", { content: message, timeSended: timeSended });
	}

	retrievePreviousMessages(socket) {
		socket.emit("chat-messages");
	}

	/* AI Dialogue*/

	createConversation(socket, characterId, conversationId) {
		socket.emit("dialogue-conversation", {
			characterId: characterId,
			conversationId: conversationId,
		});
	}

	deleteConversation(socket) {
		socket.emit("dialogue-close");
	}

	sendMessage(socket, message) {
		socket.emit("dialogue-message", { message: message });
	}

	sendAudio(socket, audio, engine) {
		socket.emit(`dialogue-audio-${engine}`, { audio: audio });
	}

	sendContext(socket, positionId, interviewAnalysisTypeId) {
		socket.emit("dialogue-contexts", {
			positionId: positionId,
			interviewAnalysisTypeId: interviewAnalysisTypeId,
		});
	}
}

export default new SocketioService();
