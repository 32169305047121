<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row justify="center" align="center">
          <v-col cols="12" class="d-flex flex-column align-center text-center">
            <v-img
              contain
              src="img/logo-virtualProfiler.png"
              class="logo-class"
            ></v-img>

            <v-card
              width="600"
              class="mt-12"
              v-if="isLogin && !isForgotPassword"
            >
              <v-card-title class="text-h5 justify-center">
                Log in to your account
              </v-card-title>

              <v-form v-model="validLogin" @submit.prevent="login">
                <v-card-text class="py-0">
                  <v-text-field
                    id="emailLogin"
                    v-model="emailLogin"
                    label="Email"
                    :rules="[emailLoginRules.required, emailLoginRules.email]"
                    outlined
                    dense
                    type="email"
                  ></v-text-field>
                  <v-text-field
                    v-model="passwordLogin"
                    label="Password"
                    :rules="[passwordLoginRules.required]"
                    outlined
                    dense
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="togglePasswordVisibility"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions class="mx-2">
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    v-if="!loggingIn"
                    :disabled="!isValidLogin"
                  >
                    Login
                  </v-btn>
                  <v-btn color="primary" block type="submit" v-else disabled>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </v-form>

              <v-btn
                text
                @click="toggleForgotPassword"
                variant="text"
                class="my-2"
              >
                Forgot your password?
              </v-btn>

              <v-alert
                v-if="messageLogin"
                outlined
                :color="successfullLogin ? 'success' : 'error'"
                :icon="successfullLogin ? 'mdi-check-circle' : 'mdi-alert'"
                dismissible
                class="mx-2"
              >
                {{ messageLogin }}
              </v-alert>
            </v-card>

            <v-card width="600" class="mt-12" v-if="isForgotPassword">
              <v-card-title class="text-h5 justify-center">
                Reset your password
              </v-card-title>

              <v-form
                v-model="validReset"
                @submit.prevent="requestPasswordReset"
              >
                <v-card-text class="py-0">
                  <v-text-field
                    id="emailReset"
                    v-model="emailReset"
                    label="Email"
                    :rules="[emailResetRules.required, emailResetRules.email]"
                    outlined
                    dense
                    type="email"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions class="mx-2">
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    v-if="!resettingPassword"
                    :disabled="!isValidRequest"
                  >
                    Request Reset
                  </v-btn>
                  <v-btn color="primary" block type="submit" v-else disabled>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </v-form>

              <v-btn
                text
                @click="toggleForgotPassword"
                variant="text"
                class="my-2"
              >
                Back to Login
              </v-btn>

              <v-alert
                v-if="messageReset"
                outlined
                :color="successfullReset ? 'success' : 'error'"
                :icon="successfullReset ? 'mdi-check-circle' : 'mdi-alert'"
                dismissible
                class="mx-2"
              >
                {{ messageReset }}
              </v-alert>
            </v-card>

            <!-- <v-card
              width="600"
              class="mt-12"
              v-if="!isLogin && !isForgotPassword"
            >
              <v-card-title class="text-h5 justify-center">
                Create Account
              </v-card-title>

              <v-form v-model="valid" @submit.prevent="register">
                <v-card-text class="py-0">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="[emailRules.required, emailRules.email]"
                    outlined
                    dense
                    type="email"
                  ></v-text-field>
                  <v-text-field
                    v-model="username"
                    label="Username"
                    :rules="[usernameRules.required]"
                    outlined
                    dense
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :rules="[passwordRules.required, passwordRules.minLength]"
                    outlined
                    dense
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    label="Repeat password"
                    :rules="[
                      confirmPasswordRules.required,
                      confirmPasswordRules.passwordMatch,
                    ]"
                    outlined
                    dense
                    type="password"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions class="mx-2">
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    v-if="!signingUp"
                    :disabled="!isValidSignUp"
                  >
                    Sign Up
                  </v-btn>
                  <v-btn color="primary" block type="submit" v-else disabled>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </v-form>

              <v-alert
                v-if="messageSignUp"
                outlined
                :color="successfullSignUp ? 'success' : 'error'"
                :icon="successfullSignUp ? 'mdi-check-circle' : 'mdi-alert'"
                dismissible
                class="mx-2"
              >
                {{ messageSignUp }}
              </v-alert>
            </v-card> -->

            <!-- <v-btn
              text
              @click="toggleForm"
              variant="text"
              class="mt-2"
              v-if="!isForgotPassword"
            >
              {{
                isLogin
                  ? "Don't have an account? Sign up"
                  : "Already have an account? Log in to your account"
              }}
            </v-btn> -->
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SocketioService from "@/services/socketioService.js";
import AuthDataService from "@/services/AuthDataService";
import Vue from "vue";

export default {
  name: "LandingPage",
  data() {
    return {
      emailLogin: "",
      passwordLogin: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      emailReset: "",
      validLogin: false,
      validReset: false,
      messageLogin: "",
      successfullLogin: false,
      loggingIn: false,
      messageSignUp: "",
      successfullSignUp: false,
      signingUp: false,
      isLogin: true,
      isForgotPassword: false,
      emailRules: {
        required: (v) => !!v || "Required field",
        email: (v) => /.+@.+\..+/.test(v) || "Invalid email address",
      },
      emailLoginRules: {
        required: (v) => !!v || "Required field",
        email: (v) => /.+@.+\..+/.test(v) || "Invalid email address",
      },
      emailResetRules: {
        required: (v) => !!v || "Required field",
        email: (v) => /.+@.+\..+/.test(v) || "Invalid email address",
      },
      usernameRules: {
        required: (v) => !!v || "Required field",
      },
      passwordRules: {
        required: (v) => !!v || "Required field",
        minLength: (v) => v.length > 7 || "Min. length 8 characters",
      },
      passwordLoginRules: {
        required: (v) => !!v || "Required field",
      },
      confirmPasswordRules: {
        required: (v) => !!v || "Required field",
        passwordMatch: (v) => v === this.password || "Passwords do not match",
      },
      messageReset: "",
      successfullReset: false,
      resettingPassword: false,
      showPassword: false,
    };
  },
  computed: {
    isValidLogin() {
      return (
        this.emailLogin !== "" &&
        this.passwordLogin !== "" &&
        this.emailLoginRules.email(this.emailLogin) !== "Invalid email address"
      );
    },
    isValidSignUp() {
      return (
        this.email !== "" &&
        this.username !== "" &&
        this.password !== "" &&
        this.confirmPassword !== "" &&
        this.emailRules.email(this.email) !== "Invalid email address" &&
        this.passwordRules.minLength(this.password) !==
          "Min. length 8 characters" &&
        this.confirmPasswordRules.passwordMatch(this.confirmPassword) !==
          "Passwords do not match"
      );
    },
    isValidRequest() {
      return (
        this.emailReset !== "" &&
        this.emailResetRules.email(this.emailReset) !== "Invalid email address"
      );
    },
  },
  mounted() {
    console.log("voy a hacer el guard");
    AuthDataService.guard().then(
      (response) => {
        console.log("response guard: ", response);
        if (response.status == 200) {
          this.$router.push("/home");
        }
      },
      (error) => {
        console.error(
          (error.response && error.response.data) ||
            error.message ||
            error.toString()
        );
      }
    );
  },
  methods: {
    login() {
      console.log("login");
      this.messageLogin = "";

      this.loggingIn = true;

      AuthDataService.login(this.emailLogin, this.passwordLogin).then(
        (response) => {
          console.log("response login: ", response);
          if (response.status == 200) {
            localStorage.setItem("user", JSON.stringify(response.data.data));

            Vue.prototype.$socket = SocketioService.setupSocketConnection(
              JSON.parse(localStorage.getItem("user")).id,
              JSON.parse(localStorage.getItem("user")).username,
              true
            );

            this.$router.push("/home");
          } else {
            this.messageLogin =
              "An error occurred while logging in. Please, try again later.";
          }

          this.loggingIn = false;
        },
        (error) => {
          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response && error.response.status == 401) {
            if (error.response.data.error == "ACCOUNT_NOT_ACTIVATED") {
              this.messageLogin =
                "Account not activated. Please, check your email inbox to activate your account.";
            } else if (error.response.data.error == "INVALID_PASSWORD") {
              this.messageLogin =
                "Invalid password. Please use the correct one.";
            } else {
              this.messageLogin =
                "An error occurred while logging in. Please, try again later.";
            }
          } else if (error.response && error.response.status == 404) {
            this.messageLogin = "Email not found. Please, use another email.";
          } else {
            this.messageLogin =
              "An error occurred while logging in. Please, try again later.";
          }

          this.loggingIn = false;
        }
      );
    },
    /* register() {
      console.log("register");
      this.messageSignUp = "";

      if (this.password !== this.confirmPassword) {
        this.successfullSignUp = false;
        this.messageSignUp = "Passwords do not match.";
      } else {
        this.signingUp = true;

        AuthDataService.register(this.email, this.username, this.password).then(
          (response) => {
            console.log("response register: ", response);
            if (response.status == 201) {
              this.successfullSignUp = true;
              this.messageSignUp =
                "Sign up successfully completed. Please check your email inbox to confirm your account and login.";
              this.email = "";
              this.username = "";
              this.password = "";
              this.confirmPassword = "";
            } else {
              this.successfullSignUp = false;
              this.messageSignUp =
                "An error occurred while signing up. Please, try again later.";
            }

            this.signingUp = false;
          },
          (error) => {
            this.successfullSignUp = false;
            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (error.response && error.response.status == 409) {
              if (error.response.data.error == "EMAIL_ALREADY_EXISTS") {
                this.messageSignUp =
                  "Email already in use. Please, provide another one.";
              } else if (
                error.response.data.error == "USERNAME_ALREADY_EXISTS"
              ) {
                this.messageSignUp =
                  "Username already in use. Please, provide another one.";
              } else {
                this.messageSignUp =
                  "An error occurred while signing up. Please, try again later.";
              }
            } else {
              this.messageSignUp =
                "An error occurred while signing up. Please, try again later.";
            }

            this.signingUp = false;
          }
        );
      }
    }, */
    requestPasswordReset() {
      console.log("request reset password");
      this.messageReset = "";

      this.resettingPassword = true;

      AuthDataService.forgotPassword(this.emailReset).then(
        (response) => {
          console.log("response forgot password: ", response);
          if (response.status == 204) {
            this.successfullReset = true;
            this.messageReset =
              "Password reset successfully requested. Please check your email inbox to reset your password.";
            this.emailReset = "";
          } else {
            this.successfullReset = false;
            this.messageReset =
              "An error occurred while requesting password. Please, try again later.";
          }

          this.resettingPassword = false;
        },
        (error) => {
          this.successfullReset = false;
          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          console.error("error.response: ", error.response);
          console.error("error.response.data: ", error.response.data);

          if (error.response && error.response.status == 404) {
            this.messageReset =
              "User not found. Please, check if the email is correct.";
          } else if (
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            localStorage.removeItem("user");
            this.$router.push("/");
          } else {
            this.messageReset =
              "An error occurred while requesting password. Please, try again later.";
          }

          this.resettingPassword = false;
        }
      );
    },
    /* toggleForm() {
      this.isLogin = !this.isLogin;
      this.messageLogin = "";
      this.messageSignUp = "";
      if (this.isLogin) {
        this.emailLogin = "";
        this.passwordLogin = "";
      } else {
        this.email = "";
        this.username = "";
        this.password = "";
        this.confirmPassword = "";
      }
    }, */
    toggleForgotPassword() {
      this.isForgotPassword = !this.isForgotPassword;
      this.messageLogin = "";
      this.messageReset = "";
      if (this.isForgotPassword) {
        this.emailReset = "";
      } else {
        this.emailLogin = "";
        this.passwordLogin = "";
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.v-card {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.v-alert {
  margin-top: 10px;
}

/* Ajustar la imagen */
.logo-class {
  width: 20% !important;
}
</style>
