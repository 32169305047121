<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row justify="center" align="center">
          <v-col cols="12" class="d-flex flex-column align-center text-center">
            <v-img
              contain
              src="img/logo-virtualProfiler.png"
              class="logo-class"
            ></v-img>

            <v-card width="600" class="mt-12">
              <v-card-title class="text-h5 justify-center">
                Activate your account
              </v-card-title>

              <v-form
                v-model="valid"
                @submit.prevent="activateAccount"
                v-if="
                  token !== null &&
                  token !== undefined &&
                  token !== 'null' &&
                  token !== 'undefined'
                "
              >
                <v-card-text class="py-0">
                  <v-container class="pt-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="password"
                          label="Password"
                          :rules="[
                            passwordRules.required,
                            passwordRules.minLength,
                          ]"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="
                            showPassword ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="togglePasswordVisibility"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-text-field
                          v-model="confirmPassword"
                          label="Repeat password"
                          :rules="[
                            confirmPasswordRules.required,
                            confirmPasswordRules.passwordMatch,
                          ]"
                          :type="showConfirmPassword ? 'text' : 'password'"
                          :append-icon="
                            showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="toggleConfirmPasswordVisibility"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="mx-2">
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    v-if="!activatingAccount"
                    :disabled="!isValidActivateAccount"
                  >
                    Activate
                  </v-btn>
                  <v-btn color="primary" block type="submit" v-else disabled>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </v-form>
              <v-alert
                v-else
                outlined
                :color="'error'"
                :icon="'mdi-alert'"
                class="mx-2"
              >
                Invalid user credentials, please make sure they are correct.
              </v-alert>

              <v-alert
                v-if="messageActivateAccount"
                outlined
                :color="successfullActivateAccount ? 'success' : 'error'"
                :icon="
                  successfullActivateAccount ? 'mdi-check-circle' : 'mdi-alert'
                "
                dismissible
                class="mx-2"
              >
                {{ messageActivateAccount }}
              </v-alert>
              <v-row class="center-content">
                <v-col cols="4">
                  <v-btn
                    class="mx-2 mb-2"
                    @click="goLogin()"
                    color="primary"
                    block
                    v-if="messageActivateAccount && successfullActivateAccount"
                  >
                    Go to login
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthDataService from "@/services/AuthDataService";
import { isValidAttribute } from "dompurify";

export default {
  name: "ActivateAccount",
  data() {
    return {
      token: null,
      valid: false,
      messageActivateAccount: "",
      successfullActivateAccount: false,
      activatingAccount: false,
      password: "",
      confirmPassword: "",
      passwordRules: {
        required: (v) => !!v || "Required field",
        minLength: (v) => v.length > 7 || "Min. length 8 characters",
      },
      confirmPasswordRules: {
        required: (v) => !!v || "Required field",
        passwordMatch: (v) => v === this.password || "Passwords do not match",
      },
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    isValidActivateAccount() {
      return (
        this.password !== "" &&
        this.confirmPassword !== "" &&
        this.passwordRules.minLength(this.password) !==
          "Min. length 8 characters" &&
        this.confirmPasswordRules.passwordMatch(this.confirmPassword) !==
          "Passwords do not match"
      );
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    console.log("this.token: ", this.token);
  },
  methods: {
    activateAccount() {
      console.log("activate account");
      this.messageActivateAccount = "";

      if (this.password !== this.confirmPassword) {
        this.successfullActivateAccount = false;
        this.messageActivateAccount = "Passwords do not match.";
      } else {
        this.activatingAccount = true;

        AuthDataService.activateAccount(this.token, this.password).then(
          (response) => {
            console.log("response activate account: ", response);
            if (response.status == 204) {
              this.successfullActivateAccount = true;
              this.messageActivateAccount =
                "Account successfully activated. You can now log in to your account.";
              this.password = "";
              this.confirmPassword = "";
            } else {
              this.successfullActivateAccount = false;
              this.messageActivateAccount =
                "An error occurred while activating account. Please, try again later.";
            }

            this.activatingAccount = false;
          },
          (error) => {
            this.successfullActivateAccount = false;
            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (error.response && error.response.status == 401) {
              if (error.response.data.error == "INVALID_TOKEN") {
                this.messageActivateAccount =
                  "Invalid token. Please, check if the token is correct.";
              } else if (error.response.data.error == "TOKEN_EXPIRED") {
                this.messageActivateAccount =
                  "Token expired. Please, generate another one to activate your account.";
              } else {
                this.messageActivateAccount =
                  "An error occurred while activating account. Please, try again later.";
              }
            } else if (error.response && error.response.status == 404) {
              this.messageActivateAccount =
                "User not found. Please, check if the token is correct or if the account is already activated.";
            } else {
              this.messageActivateAccount =
                "An error occurred while activating account. Please, try again later.";
            }

            this.activatingAccount = false;
          }
        );
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    goLogin() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.v-card {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.v-alert {
  margin-top: 10px;
}

/* Ajustar la imagen */
.logo-class {
  width: 20% !important;
}
</style>
