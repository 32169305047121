<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12 pt-0">
                <v-card-title class="pt-0">
                  <h1>Access to the interview</h1>
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-6">
                <v-row class="my-0 mx-6">
                  <h6>Name:</h6>
                  <v-col class="col col-12">
                    <p>{{ interview.name }}</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 mx-6">
                  <h6>Description:</h6>
                  <v-col class="col col-12">
                    <p>{{ interview.description }}</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 mx-6">
                  <h6>Position:</h6>
                  <v-col class="col col-12">
                    <p>{{ interview.position.name }}</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 mx-6">
                  <h6>Competencies:</h6>
                  <v-col class="col col-12">
                    <v-list color="transparent">
                      <v-list-item v-for="(competence, index) in interview.competencies" :key="index">
                        <v-list-item-icon>
                          <v-icon>mdi-circle-small</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            competence.name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col col-6">
                <v-row>
                  <v-col cols="4">
                    <v-select class="mx-4" id="camera" :items="videoDevices" item-text="label" item-value="deviceId"
                      label="Select camera *" v-model="selectedCamera" @change="handleCameraChange"></v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-select class="mx-4" id="microphone" :items="audioDevices" item-text="label" item-value="deviceId"
                      label="Select microphone *" v-model="selectedMicrophone"></v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-select class="mx-4" id="speaker" :items="outputDevices" item-text="label" item-value="deviceId"
                      label="Select speakers *" v-model="selectedSpeaker"></v-select>
                  </v-col>
                </v-row>
                <v-row class="my-0 mx-6">
                  <v-col>
                    <div class="center-content">
                      <video ref="localVideo" id="localVideo" class="call-player-video"
                        poster="img/logo-virtualProfiler-girado.png" autoplay></video>
                    </div>
                  </v-col>
                  <v-col class="col col-12 center-content">
                    <v-tooltip top v-if="microActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(false)" class="mx-4 btn-micro-camera"
                          :disabled="!selectedMicrophone">
                          <v-icon size="26" color="primary">mdi-microphone</v-icon>
                        </v-btn>
                      </template>
                      <span>Deactivate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(true)" class="mx-4 btn-micro-camera"
                          :disabled="!selectedMicrophone">
                          <v-icon size="26" color="primary">mdi-microphone-off</v-icon>
                        </v-btn>
                      </template>
                      <span>Activate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-if="cameraActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(false)" class="mx-4 btn-micro-camera"
                          :disabled="!selectedCamera">
                          <v-icon size="26" color="primary">mdi-video</v-icon>
                        </v-btn>
                      </template>
                      <span>Deactivate camera</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(true)" class="mx-4 btn-micro-camera"
                          :disabled="!selectedCamera">
                          <v-icon size="26" color="primary">mdi-video-off</v-icon>
                        </v-btn>
                      </template>
                      <span>Activate camera</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12 d-flex justify-center">
                <v-btn v-if="
                  interviewStatus === 'COMPLETED' ||
                  interviewStatus === 'ANALYSED'
                " class="ok-modal" @click="accessInterviewCandidateDialog = true" disabled>
                  Closed interview
                </v-btn>
                <v-btn class="ok-modal" @click="accessInterviewCandidateDialog = true" v-else-if="!access" disabled>
                  Enter
                </v-btn>
                <v-btn class="ok-modal" @click="accessInterviewCandidateDialog = true" v-else-if="
                  selectedCamera === null ||
                  selectedMicrophone === null ||
                  selectedSpeaker === null
                " disabled>
                  Enter
                </v-btn>
                <v-btn class="ok-modal" @click="accessInterviewCandidateDialog = true" v-else>
                  Enter
                </v-btn>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon class="ml-2 more-info-btn">
                      <v-icon size="20" color="primary">mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="
                    interviewStatus === 'COMPLETED' ||
                    interviewStatus === 'ANALYSED'
                  ">The interview has been already completed.</span>
                  <span v-else-if="!access && reason == 'NO_INTERVIEWER'">
                    You cannot enter the interview until the
                    interviewer
                    agrees
                    to do so.
                  </span>
                  <span v-else-if="!access && reason == 'ALREADY_IN_CALL'">
                    You cannot enter because the candidate is already in the call
                  </span>
                  <span v-else-if="
                    selectedCamera === null ||
                    selectedMicrophone === null ||
                    selectedSpeaker === null
                  ">You cannot enter the interview until you select all
                    hardware devices.</span><span v-else>You can access to the interview.</span>
                </v-tooltip>

                <!-- <v-btn v-bind="$attrs" v-on="$on" icon class="ml-2">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon size="20" color="primary" v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span
                      v-if="
                        interviewStatus === 'COMPLETED' ||
                        interviewStatus === 'ANALYSED'
                      "
                      >The interview has ended.</span
                    >
                    <span v-else-if="!access"
                      >You cannot enter the interview until the interviewer
                      agrees to do so.</span
                    >
                  </v-tooltip>
                </v-btn> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>

      <v-dialog v-model="accessInterviewCandidateDialog" max-width="550" min-height="550">
        <v-card>
          <v-card-title>
            <h5>Access to the interview</h5>
          </v-card-title>
          <v-card-text class="pb-0">
            <p>
              The content of the call will be stored for analytical purposes.
              (Pendiente de texto aportado por BEONIT)
            </p>
            <v-checkbox v-model="acceptTerms" :label="'I accept the terms and conditions'"></v-checkbox>
          </v-card-text>
          <v-card-actions class="px-4 pt-0 pb-4">
            <v-spacer></v-spacer>
            <v-btn text @click="accessInterviewCandidateDialog = false" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn @click="enterInterview" class="ok-modal" :disabled="!acceptTerms">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import SocketioService from "@/services/socketioService.js";
import UserMediaController from "@/services/UserMedia.js";
import InterviewsDataService from "@/services/InterviewsDataService";
import CandidatesDataService from "@/services/CandidatesDataService";
import Vue from "vue";

export default {
  name: "WaitingRoomCandidate",
  data() {
    return {
      candidateName: "",
      candidates: [],
      microActivated: true,
      cameraActivated: true,
      selfUserStream: null,
      candidateId: null,
      interview: {
        name: "prueba",
        description: "Prueba",
        position: { name: "prueba" },
        competencies: [{ name: "prueba" }],
      },
      accessInterviewCandidateDialog: false,
      interviewStatus: null,
      access: true,
      reason: "",
      acceptTerms: false,
      videoDevices: [],
      audioDevices: [],
      outputDevices: [],
      selectedCamera: null,
      selectedMicrophone: null,
      selectedSpeaker: null,
    };
  },
  components: {},
  created() {
    SocketioService.emisor.$on("callRoomResponse", async (data) => {
      this.$socket.callRoom = data.callId;
      this.access = data.canAccess;
      this.reason = data.reason;

    });
  },
  async mounted() {
    if (this.$route.query.token === undefined) this.$router.push("/error");
    else {
      const { token } = this.$route.query;

      await this.getDevices();
      await InterviewsDataService.getInterviewInfo(token)
        .then(async (res) => {
          if (res.status === 200) {
            this.interview = res.data.data.interview;
            this.interviewStatus = res.data.data.status;
            console.log("res.data", res.data);
          }
        })
        .catch((err) => console.error(err));

      await CandidatesDataService.findAllInfoFromCandidate(token).then(
        async (res) => {
          if (res.status === 200) {
            console.log("res.data.data", res.data.data);

            this.candidateName = res.data.data.candidateUsername;
            this.candidateId = res.data.data.candidateId;
            localStorage.removeItem("isCandidate");
            localStorage.removeItem("query");

            Vue.prototype.$socket = SocketioService.setupSocketConnection(
              this.candidateId,
              null,
              false
            );

            await InterviewsDataService.registerCall(
              this.candidateId,
              res.data.data.interviewId,
              res.data.data.interviewerId
            ).then((res) => {
              if (res.status === 200) {
                SocketioService.createCallRoom(this.$socket, res.data.data.id);
              }
            });
          }
        }
      );

      UserMediaController.getUserMedia(
        function (stream) {
          if (!stream) {
            stream = UserMediaController.getPlaceholderStream();
          }

          if (stream !== null) {
            // const localVideo = document.getElementById("localVideo");
            // localVideo.srcObject = new MediaStream(stream.getVideoTracks());

            // Apply options to stream
            UserMediaController.setMediaStreamVideoEnabled(
              stream,
              this.cameraActivated
            );
            UserMediaController.setMediaStreamAudioEnabled(
              stream,
              this.microActivated
            );

            // Get the stream
            this.selfUserStream = stream;
          } else {
            console.error("Error getting user media");
          }
        }.bind(this)
      );
    }
  },
  beforeUnmount() { },
  beforeRouteLeave(to, from, next) {
    if (this.selfUserStream)
      UserMediaController.stopStream(this.selfUserStream);
    this.selfUserStream = null;
    next();
  },
  methods: {
    async getDevices() {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log("devices: ", devices);
        this.videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        this.audioDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );
        this.outputDevices = devices.filter(
          (device) => device.kind === "audiooutput"
        );
      } catch (err) {
        console.error("Error al obtener dispositivos:", err);
      }
    },
    async setCameraStream(deviceId) {
      // Detén cualquier flujo de medios existente
      if (this.selfUserStream) {
        this.selfUserStream.getTracks().forEach((track) => track.stop());
      }

      // Configura el flujo de medios para la cámara seleccionada
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: deviceId } },
        });
        this.selfUserStream = stream;

        // Asigna el flujo al elemento <video>
        const videoElement = this.$refs.localVideo;
        videoElement.srcObject = stream;
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    },
    async handleCameraChange() {
      // Cambia la fuente del video al cambiar de cámara
      await this.setCameraStream(this.selectedCamera);
    },
    enableAudio(enabled) {
      this.microActivated = enabled;
      UserMediaController.setMediaStreamAudioEnabled(
        this.selfUserStream,
        enabled
      );
    },
    enableVideo(enabled) {
      this.cameraActivated = enabled;
      UserMediaController.setMediaStreamVideoEnabled(
        this.selfUserStream,
        enabled
      );
    },
    enterInterview() {
      console.log("enterInterview");
      this.$router.push({
        name: "interview",
        params: {
          interview: this.interview,
          video: this.cameraActivated,
          audio: this.microActivated,
          interviewerName: "Interviewer",
          candidateName: this.candidateName,
          isCandidate: true,
          redirectParams: this.$route.query,
          candidateId: this.candidateId,
          camera: this.selectedCamera,
          microphone: this.selectedMicrophone,
          speaker: this.selectedSpeaker,
        },
      });
    },
  },
};
</script>

<style scoped></style>
