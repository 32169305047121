import http from "../http-common";

const API_URL_PATH = "users/";
const API_URL_PATH_WITHOUT_BAR = "users";

class UsersDataService {
  findAll(page, limit) {
    return http.get(
      API_URL_PATH_WITHOUT_BAR + "?page=" + page + "&limit=" + limit
    );
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }
}

export default new UsersDataService();
