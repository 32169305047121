import http from "../http-common";

const API_URL_PATH = "candidates/";
const API_URL_PATH_WITHOUT_BAR = "candidates";
const API_URL_PATH_UPLOAD = "candidates/cvs";

class CandidatesDataService {
	findAll(page, limit) {
		console.log("findAll en el dataservice page: ", page);
		console.log("findAll en el dataservice limit: ", limit);
		return http.get(
			API_URL_PATH_WITHOUT_BAR + "?page=" + page + "&limit=" + limit
		);
	}

	findOne(candidateId, interviewId, username, email) {
		return http.post(API_URL_PATH, {
			candidateId: candidateId,
			interviewId: interviewId,
			username: username,
			email: email,
		});
	}

	create(candidate) {
		return http.post(API_URL_PATH, candidate);
	}

	update(candidate) {
		return http.put(API_URL_PATH + candidate.id, candidate);
	}

	delete(id) {
		return http.delete(API_URL_PATH + id);
	}

	upload(files, onUploadCVs) {
		return http.post(API_URL_PATH_UPLOAD, files, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadCVs,
		});
	}

	getCV(id) {
		return http.get(API_URL_PATH + id + "/cvs");
	}

	updateCV(id, data) {
		return http.put(API_URL_PATH + id, { data: data });
	}

	// findAllCVs() {
	//   return http.get(API_URL_PATH_UPLOAD);
	// }

	// getCV(id) {
	//   return http.get(API_URL_PATH_UPLOAD + "/" + id);
	// }

	// deleteCV(id) {
	//   return http.delete(API_URL_PATH_UPLOAD + "/" + id);
	// }

	findAllInterviewsFromCandidate(id, page, limit) {
		console.log("findAllInterviewsFromCandidate en el dataservice id: ", id);
		console.log(
			"findAllInterviewsFromCandidate en el dataservice page: ",
			page
		);
		console.log(
			"findAllInterviewsFromCandidate en el dataservice limit: ",
			limit
		);
		return http.get(
			API_URL_PATH + id + "/interviews?page=" + page + "&limit=" + limit
		);
	}

	findAllInfoFromCandidate(id) {
		return http.get(API_URL_PATH + id + "/info");
	}
}

export default new CandidatesDataService();
