import http from "../http-common";

const API_URL_PATH = "jobs/";
const API_URL_PATH_WITHOUT_BAR = "jobs";

class JobsDataService {
  findAll(page, limit) {
    return http.get(
      API_URL_PATH_WITHOUT_BAR + "?page=" + page + "&limit=" + limit
    );
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(job) {
    return http.post(API_URL_PATH, job);
  }

  update(job) {
    return http.put(API_URL_PATH + job.id, job);
  }

  delete(id) {
    return http.delete(API_URL_PATH + id);
  }
}

export default new JobsDataService();
