import http from "../http-common";

const API_URL_PATH = "positions/";
const API_URL_PATH_WITHOUT_BAR = "positions";

class PositionsDataService {
  findAll(page, limit) {
    return http.get(
      API_URL_PATH_WITHOUT_BAR + "?page=" + page + "&limit=" + limit
    );
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(position) {
    return http.post(API_URL_PATH, position);
  }

  update(position) {
    return http.put(API_URL_PATH + position.id, position);
  }

  delete(id) {
    return http.delete(API_URL_PATH + id);
  }
}

export default new PositionsDataService();
