<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Competencies</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateCompetence"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create competence
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateCompetence"
                  outlined
                  :color="successfullCreateCompetence ? 'success' : 'error'"
                  :icon="
                    successfullCreateCompetence
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateCompetence }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteCompetence"
                  outlined
                  :color="successfullDeleteCompetence ? 'success' : 'error'"
                  :icon="
                    successfullDeleteCompetence
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteCompetence }}
                </v-alert>
                <v-alert
                  v-if="messageEditCompetence"
                  outlined
                  :color="successfullEditCompetence ? 'success' : 'error'"
                  :icon="
                    successfullEditCompetence ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditCompetence }}
                </v-alert>

                <v-data-table
                  :headers="headers"
                  :items="competencies"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait"
                  multi-sort
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.itemsPerPage"
                  :page.sync="pagination.page"
                  @update:page="handlePageChange"
                >
                  <template v-slot:[`item.hasTraining`]="{ item }">
                    <v-card-text
                      v-if="item.hasTraining"
                      class="d-flex align-end"
                      ><v-icon size="20" color="green"
                        >mdi-check</v-icon
                      ></v-card-text
                    >
                    <v-card-text v-else class="d-flex align-end"
                      ><v-icon size="20" color="red"
                        >mdi-close</v-icon
                      ></v-card-text
                    >
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openEditCompetence(item)">
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit competence</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteCompetence(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete competence</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageCompetencies"
              outlined
              :color="successfullCompetencies ? 'success' : 'error'"
              :icon="successfullCompetencies ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageCompetencies }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageInfo"
              outlined
              :color="successfullInfo ? 'success' : 'error'"
              :icon="successfullInfo ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageInfo }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createCompetenceDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createCompetenceForm" @submit.prevent="createCompetence">
          <v-card-title>
            <h5>Create Competence</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="competence.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Legacy name *"
                    v-model="competence.legacyName"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    label="Description *"
                    v-model="competence.description"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-switch
                    v-model="competence.hasTraining"
                    flat
                    label="Has training"
                  ></v-switch>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Extra weight *"
                    type="number"
                    :min="0"
                    v-model="competence.extraWeight"
                    :step="1"
                    :rules="rulesNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Type"
                    :items="info.type"
                    v-model="competence.type"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Category"
                    :items="info.category"
                    v-model="competence.category"
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateCompetenceInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullCreateCompetenceInside ? 'success' : 'error'"
                :icon="
                  successfullCreateCompetenceInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateCompetenceInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateCompetence" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!creatingCompetence">
              Create
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editCompetenceDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editCompetenceForm" @submit.prevent="editCompetence">
          <v-card-title>
            <h5>Update Competence</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Legacy name *"
                    v-model="itemToEdit.legacyName"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    label="Description *"
                    v-model="itemToEdit.description"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-switch
                    v-model="itemToEdit.hasTraining"
                    flat
                    label="Has training"
                  ></v-switch>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Extra weight *"
                    type="number"
                    :min="0"
                    v-model="itemToEdit.extraWeight"
                    :step="1"
                    :rules="rulesNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Type"
                    :items="info.type"
                    v-model="itemToEdit.type"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Category"
                    :items="info.category"
                    v-model="itemToEdit.category"
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageEditCompetenceInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullEditCompetenceInside ? 'success' : 'error'"
                :icon="
                  successfullEditCompetenceInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditCompetenceInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editCompetenceDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!editingCompetence">
              Update
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteCompetenceDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Competence</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected competence? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteCompetenceDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingCompetence">
            <v-btn @click="deleteCompetence" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deleteCompetence" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsCompetenceDialog" max-width="800">
      <v-card>
        <v-form ref="detailsCompetenceForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Legacy name:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">{{ itemToShow.legacyName }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Description:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">{{ itemToShow.description }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Has training:</h6>
                </v-col>
                <v-col cols="3">
                  <p class="mb-0" v-if="itemToShow.hasTraining">
                    <v-icon size="20" color="green">mdi-check</v-icon>
                  </p>
                  <p class="mb-0" v-else>
                    <v-icon size="20" color="red">mdi-close</v-icon>
                  </p>
                </v-col>
                <v-col cols="3">
                  <h6 class="details-subtitles">Extra weight:</h6>
                </v-col>
                <v-col cols="3">
                  <p class="mb-0">{{ itemToShow.extraWeight }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Type:</h6>
                </v-col>
                <v-col cols="3">
                  <p class="mb-0">{{ itemToShow.type }}</p>
                </v-col>
                <v-col cols="3">
                  <h6 class="details-subtitles">Category:</h6>
                </v-col>
                <v-col cols="3">
                  <p class="mb-0">{{ itemToShow.category }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsCompetenceDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CompetenciesDataService from "@/services/CompetenciesDataService";

export default {
  name: "CompetenciesList",
  data() {
    return {
      competencies: [],
      successfullCompetencies: false,
      messageCompetencies: "",
      info: {},
      successfullInfo: false,
      messageInfo: "",
      loading: false,
      successfullCreateCompetence: false,
      messageCreateCompetence: "",
      successfullCreateCompetenceInside: false,
      messageCreateCompetenceInside: "",
      successfullEditCompetence: false,
      messageEditCompetence: "",
      successfullEditCompetenceInside: false,
      messageEditCompetenceInside: "",
      successfullDeleteCompetence: false,
      messageDeleteCompetence: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Legacy name", value: "legacyName" },
        { text: "Type", value: "type" },
        { text: "Category", value: "category" },
        { text: "Has training", value: "hasTraining" },
        { text: "Extra weight", value: "extraWeight" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalPages: 1,
        totalItems: 0,
      },
      competence: {
        name: "",
        legacyName: "",
        description: "",
        hasTraining: false,
        extraWeight: "0",
        type: "Competencia",
        category: "Gestión Personal",
        status: 1,
      },
      rules: [(v) => !!v || "Required field"],
      rulesNumber: [(v) => v !== "" || "Required field"],
      createCompetenceDialog: false,
      creatingCompetence: false,
      editCompetenceDialog: false,
      editingCompetence: false,
      deleteCompetenceDialog: false,
      deletingCompetence: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      showDetailsCompetenceDialog: false,
    };
  },
  async mounted() {
    await this.getInfo();
    await this.getAllCompetencies(1);
  },
  methods: {
    getAllCompetencies(page) {
      this.loading = true;

      CompetenciesDataService.findAll(page, this.pagination.itemsPerPage).then(
        (response) => {
          if (response.status == 200) {
            this.competencies = response.data.data;

            if (this.competencies.length !== 0) {
              this.pagination.page = page;
              this.pagination.totalPages = response.data.links.totalPages || 1;

              this.pagination.totalItems =
                response.data.links.totalItems ||
                this.pagination.totalPages * this.pagination.itemsPerPage;
            }
          } else {
            this.successfullCompetencies = false;
            this.messageCompetencies =
              "An error has occurred retrieving the list of competencies. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullCompetencies = false;
          this.messageCompetencies =
            "An error has occurred retrieving the list of competencies. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    handlePageChange(newPage) {
      console.log("handlePageChange: ", newPage);
      this.pagination.page = newPage;
      this.getAllCompetencies(newPage);
    },
    getInfo() {
      this.loading = true;

      CompetenciesDataService.getInfo().then(
        (response) => {
          if (response.status == 200) this.info = response.data.data;
          else {
            this.successfullInfo = false;
            this.messageInfo =
              "An error has occurred retrieving the competencies information. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullInfo = false;
          this.messageInfo =
            "An error has occurred retrieving the competencies information. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openCreateCompetence() {
      this.createCompetenceDialog = true;
    },
    createCompetence() {
      this.successfullCreateCompetence = true;
      this.messageCreateCompetence = "";

      if (this.$refs.createCompetenceForm.validate()) {
        this.creatingCompetence = true;

        CompetenciesDataService.create(this.competence).then(
          (response) => {
            if (response.status == 201) {
              this.getAllCompetencies(1);

              this.messageCreateCompetence = "";
              this.successfullCreateCompetence = true;

              setTimeout(() => {
                this.messageCreateCompetence =
                  "Competence successfully created.";
              }, 10);
            } else {
              this.messageCreateCompetence = "";
              this.successfullCreateCompetence = true;

              setTimeout(() => {
                this.messageCreateCompetence =
                  "An error has occurred creating the competence. Please, try again later.";
              }, 10);
            }

            this.createCompetenceDialog = false;
            this.creatingCompetence = false;
            this.competence = {
              name: "",
              legacyName: "",
              description: "",
              hasTraining: false,
              extraWeight: "0",
              type: "Competencia",
              category: "Gestión Personal",
              status: 1,
            };
          },
          (error) => {
            this.messageCreateCompetenceInside = "";
            this.messageCreateCompetence = "";

            if (error.response.status === 409) {
              this.successfullCreateCompetenceInside = false;

              setTimeout(() => {
                this.messageCreateCompetenceInside =
                  "Competence already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullCreateCompetence = false;

              setTimeout(() => {
                this.messageCreateCompetence =
                  "An error has occurred creating the competence. Please, try again later.";
              }, 10);

              this.createCompetenceDialog = false;
              this.competence = {
                name: "",
                legacyName: "",
                description: "",
                hasTraining: false,
                extraWeight: "0",
                type: "Competencia",
                category: "Gestión Personal",
                status: 1,
              };
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.creatingCompetence = false;
          }
        );
      }
    },
    cancelCreateCompetence() {
      this.createCompetenceDialog = false;
      this.competence = {
        name: "",
        legacyName: "",
        description: "",
        hasTraining: false,
        extraWeight: "0",
        type: "Competencia",
        category: "Gestión Personal",
        status: 1,
      };
    },
    openEditCompetence(competence) {
      this.itemToEdit = { ...competence };
      this.editCompetenceDialog = true;
    },
    editCompetence() {
      this.successfullEditCompetence = true;

      if (this.$refs.editCompetenceForm.validate()) {
        this.editingCompetence = true;

        CompetenciesDataService.update(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllCompetencies(1);
              this.messageEditCompetence = "";
              this.successfullEditCompetence = true;

              setTimeout(() => {
                this.messageEditCompetence = "Competence successfully updated.";
              }, 10);
            } else {
              this.messageEditCompetence = "";
              this.successfullEditCompetence = true;

              setTimeout(() => {
                this.messageEditCompetence =
                  "An error has occurred updating the competence. Please, try again later.";
              }, 10);
            }

            this.editCompetenceDialog = false;
            this.editingCompetence = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditCompetenceInside = "";
            this.messageEditCompetence = "";

            if (error.response.status === 409) {
              this.successfullEditCompetenceInside = false;

              setTimeout(() => {
                this.messageEditCompetenceInside =
                  "Competence already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditCompetence = false;

              setTimeout(() => {
                this.messageEditCompetence =
                  "An error has occurred updating the competence. Please, try again later.";
              }, 10);

              this.editCompetenceDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingCompetence = false;
          }
        );
      }
    },
    openDeleteCompetence(competence) {
      this.itemToDelete = { ...competence };
      this.deleteCompetenceDialog = true;
    },
    deleteCompetence() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingCompetence = true;

        CompetenciesDataService.delete(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllCompetencies(1);
              this.messageDeleteCompetence = "";
              this.successfullDeleteCompetence = true;

              setTimeout(() => {
                this.messageDeleteCompetence =
                  "Competence successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteCompetence = "";
              this.successfullDeleteCompetence = false;

              setTimeout(() => {
                this.messageDeleteCompetence =
                  "An error has occurred deleting the competence. Please, try again later.";
              }, 10);
            }

            this.deleteCompetenceDialog = false;
            this.deletingCompetence = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteCompetence = "";
            this.successfullDeleteCompetence = false;

            setTimeout(() => {
              this.messageDeleteCompetence =
                "An error has occurred deleting the competence. Please, try again later.";
            }, 10);

            this.deleteCompetenceDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingCompetence = false;
          }
        );
      } else {
        this.messageDeleteCompetence = "";
        this.successfullDeleteCompetence = false;

        setTimeout(() => {
          this.messageDeleteCompetence =
            "An error has occurred deleting the competence. Please, try again later.";
        }, 10);

        this.deleteCompetenceDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(competence) {
      this.itemToShow = { ...competence };
      this.showDetailsCompetenceDialog = true;
    },
  },
};
</script>
