<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Contexts</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateContext"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create context
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateContext"
                  outlined
                  :color="successfullCreateContext ? 'success' : 'error'"
                  :icon="
                    successfullCreateContext ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateContext }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteContext"
                  outlined
                  :color="successfullDeleteContext ? 'success' : 'error'"
                  :icon="
                    successfullDeleteContext ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteContext }}
                </v-alert>
                <v-alert
                  v-if="messageEditContext"
                  outlined
                  :color="successfullEditContext ? 'success' : 'error'"
                  :icon="
                    successfullEditContext ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditContext }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="contexts"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait"
                  multi-sort
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.itemsPerPage"
                  :page.sync="pagination.page"
                  @update:page="handlePageChange"
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openEditContext(item)">
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit context</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openDeleteContext(item)">
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete context</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageContexts"
              outlined
              :color="successfullContexts ? 'success' : 'error'"
              :icon="successfullContexts ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageContexts }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createContextDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createContextForm" @submit.prevent="createContext">
          <v-card-title>
            <h5>Create Context</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="context.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    label="Content *"
                    v-model="context.content"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateContextInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullCreateContextInside ? 'success' : 'error'"
                :icon="
                  successfullCreateContextInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateContextInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateContext" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!creatingContext">
              Create
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editContextDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editContextForm" @submit.prevent="editContext">
          <v-card-title>
            <h5>Update Context</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    label="Content *"
                    v-model="itemToEdit.content"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-alert
                v-if="messageEditContextInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullEditContextInside ? 'success' : 'error'"
                :icon="
                  successfullEditContextInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditContextInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="editContextDialog = false" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!editingContext">
              Update
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteContextDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Context</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected context? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="deleteContextDialog = false" class="cancel-modal">
            Cancel
          </v-btn>

          <template v-if="!deletingContext">
            <v-btn @click="deleteContext" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deleteContext" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ContextsDataService from "@/services/ContextsDataService";

export default {
  name: "ContextsList",
  data() {
    return {
      contexts: [],
      successfullContexts: false,
      messageContexts: "",
      loading: false,
      successfullCreateContext: false,
      messageCreateContext: "",
      successfullCreateContextInside: false,
      messageCreateContextInside: "",
      successfullEditContext: false,
      messageEditContext: "",
      successfullEditContextInside: false,
      messageEditContextInside: "",
      successfullDeleteContext: false,
      messageDeleteContext: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Content", value: "content" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalPages: 1,
        totalItems: 0,
      },
      context: {
        name: "",
        content: "",
      },
      rules: [(v) => !!v || "Required field"],
      createContextDialog: false,
      creatingContext: false,
      editContextDialog: false,
      editingContext: false,
      deleteContextDialog: false,
      deletingContext: false,
      itemToEdit: {},
      itemToDelete: {},
    };
  },
  async mounted() {
    await this.getAllContexts(1);
  },
  methods: {
    getAllContexts(page) {
      this.loading = true;

      ContextsDataService.findAll(page, this.pagination.itemsPerPage).then(
        (response) => {
          if (response.status == 200) {
            this.contexts = response.data.data;

            if (this.contexts.length !== 0) {
              this.pagination.page = page;
              this.pagination.totalPages = response.data.links.totalPages || 1;

              this.pagination.totalItems =
                response.data.links.totalItems ||
                this.pagination.totalPages * this.pagination.itemsPerPage;
            }
          } else {
            this.successfullContexts = false;
            this.messageContexts =
              "An error has occurred retrieving the list of contexts. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullContexts = false;
          this.messageContexts =
            "An error has occurred retrieving the list of contexts. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    handlePageChange(newPage) {
      console.log("handlePageChange: ", newPage);
      this.pagination.page = newPage;
      this.getAllContexts(newPage);
    },
    openCreateContext() {
      this.createContextDialog = true;
    },
    createContext() {
      this.successfullCreateContext = true;
      this.messageCreateContext = "";

      if (this.$refs.createContextForm.validate()) {
        this.creatingContext = true;

        ContextsDataService.create(this.context).then(
          (response) => {
            if (response.status == 201) {
              this.getAllContexts(1);

              this.messageCreateContext = "";
              this.successfullCreateContext = true;

              setTimeout(() => {
                this.messageCreateContext = "Context successfully created.";
              }, 10);
            } else {
              this.messageCreateContext = "";
              this.successfullCreateContext = false;

              setTimeout(() => {
                this.messageCreateContext =
                  "An error has occurred creating the context. Please, try again later.";
              }, 10);
            }
            this.createContextDialog = false;
            this.creatingContext = false;
            this.context = {
              name: "",
              content: "",
            };
          },
          (error) => {
            this.messageCreateContextInside = "";
            this.messageCreateContext = "";

            if (error.response.status === 409) {
              this.successfullCreateContextInside = false;

              setTimeout(() => {
                this.messageCreateContextInside =
                  "Context already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullCreateContext = false;

              setTimeout(() => {
                this.messageCreateContext =
                  "An error has occurred creating the context. Please, try again later.";
              }, 10);

              this.createContextDialog = false;
              this.context = {
                name: "",
                content: "",
              };
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.creatingContext = false;
          }
        );
      }
    },
    cancelCreateContext() {
      this.createContextDialog = false;
      this.context = {
        name: "",
        content: "",
      };
    },
    openEditContext(context) {
      this.itemToEdit = { ...context };
      this.editContextDialog = true;
    },
    editContext() {
      this.successfullEditContext = true;

      if (this.$refs.editContextForm.validate()) {
        this.editingContext = true;

        ContextsDataService.update(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllContexts(1);
              this.messageEditContext = "";
              this.successfullEditContext = true;

              setTimeout(() => {
                this.messageEditContext = "Context successfully updated.";
              }, 10);
            } else {
              this.messageEditContext = "";
              this.successfullEditContext = true;

              setTimeout(() => {
                this.messageEditContext =
                  "An error has occurred updating the context. Please, try again later.";
              }, 10);
            }

            this.editContextDialog = false;
            this.editingContext = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditContextInside = "";
            this.messageEditContext = "";

            if (error.response.status === 409) {
              this.successfullEditContextInside = false;

              setTimeout(() => {
                this.messageEditContextInside =
                  "Context already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditContext = false;

              setTimeout(() => {
                this.messageEditContext =
                  "An error has occurred updating the context. Please, try again later.";
              }, 10);

              this.editContextDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingContext = false;
          }
        );
      }
    },
    openDeleteContext(context) {
      this.itemToDelete = { ...context };
      this.deleteContextDialog = true;
    },
    deleteContext() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingContext = true;

        ContextsDataService.delete(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllContexts(1);
              this.messageDeleteContext = "";
              this.successfullDeleteContext = true;

              setTimeout(() => {
                this.messageDeleteContext = "Context successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteContext = "";
              this.successfullDeleteContext = false;

              setTimeout(() => {
                this.messageDeleteContext =
                  "An error has occurred deleting the context. Please, try again later.";
              }, 10);
            }

            this.deleteContextDialog = false;
            this.deletingContext = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteContext = "";
            this.successfullDeleteContext = false;

            setTimeout(() => {
              this.messageDeleteContext =
                "An error has occurred deleting the context. Please, try again later.";
            }, 10);

            this.deleteContextDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingContext = false;
          }
        );
      } else {
        this.messageDeleteContext = "";
        this.successfullDeleteContext = false;

        setTimeout(() => {
          this.messageDeleteContext =
            "An error has occurred deleting the context. Please, try again later.";
        }, 10);

        this.deleteContextDialog = false;
        this.itemToDelete = {};
      }
    },
  },
};
</script>
