<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Interview Types</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateInterviewType"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create interview type
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateInterviewType"
                  outlined
                  :color="successfullCreateInterviewType ? 'success' : 'error'"
                  :icon="
                    successfullCreateInterviewType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateInterviewType }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteInterviewType"
                  outlined
                  :color="successfullDeleteInterviewType ? 'success' : 'error'"
                  :icon="
                    successfullDeleteInterviewType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteInterviewType }}
                </v-alert>
                <v-alert
                  v-if="messageEditInterviewType"
                  outlined
                  :color="successfullEditInterviewType ? 'success' : 'error'"
                  :icon="
                    successfullEditInterviewType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditInterviewType }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="interviewTypes"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.itemsPerPage"
                  :page.sync="pagination.page"
                  @update:page="handlePageChange"
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openEditInterviewType(item)"
                        >
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit interview type</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteInterviewType(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete interview type</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageInterviewTypes"
              outlined
              :color="successfullInterviewTypes ? 'success' : 'error'"
              :icon="
                successfullInterviewTypes ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageInterviewTypes }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageComponents"
              outlined
              :color="successfullComponents ? 'success' : 'error'"
              :icon="successfullComponents ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageComponents }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageAnalysisTypes"
              outlined
              :color="successfullAnalysisTypes ? 'success' : 'error'"
              :icon="
                successfullAnalysisTypes ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageAnalysisTypes }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createInterviewTypeDialog" max-width="800" persistent>
      <v-card>
        <v-form
          ref="createInterviewTypeForm"
          @submit.prevent="createInterviewType"
        >
          <v-card-title>
            <h5>Create Interview Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="interviewType.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description"
                    v-model="interviewType.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-select
                    ref="menuAnalysisTypeCreate"
                    label="Analysis Type *"
                    :items="analysisTypesCreate"
                    v-model="interviewType.interviewAnalysisTypeId"
                    item-text="name"
                    item-value="id"
                    :loading="loadingAnalysisTypesCreate"
                    attach
                  ></v-select>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="8" class="pt-0">
                      <h6 class="details-subtitles">Steps</h6>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-btn
                        text
                        class="float-right"
                        color="primary"
                        @click="addStepCreate(interviewType)"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table
                    :headers="headersSteps"
                    :items="interviewType.steps"
                  >
                    <template v-slot:[`item.order`]="{ item }">
                      {{ item.order + 1 }}
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <v-text-field
                        v-model="item.name"
                        :rules="rules"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                      <v-text-field v-model="item.description"></v-text-field>
                    </template>
                    <template v-slot:[`item.componentId`]="{ item }">
                      <v-select
                        :items="componentsCreate"
                        v-model="item.componentId"
                        item-text="name"
                        item-value="id"
                        :loading="loadingComponentsCreate"
                      ></v-select>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        size="small"
                        @click="deleteStep(interviewType, item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateInterviewTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullCreateInterviewTypeInside ? 'success' : 'error'
                "
                :icon="
                  successfullCreateInterviewTypeInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateInterviewTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateInterviewType" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!creatingInterviewType">
              Create
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editInterviewTypeDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editInterviewTypeForm" @submit.prevent="editInterviewType">
          <v-card-title>
            <h5>Update Interview Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description"
                    v-model="itemToEdit.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-select
                    label="Analysis Type *"
                    :items="analysisTypesEdit"
                    v-model="itemToEdit.interviewAnalysisTypeId"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="8" class="pt-0">
                      <h6 class="details-subtitles">Steps</h6>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-btn
                        text
                        class="float-right"
                        color="primary"
                        @click="addStepEdit(itemToEdit)"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table
                    :headers="headersSteps"
                    :items="itemToEdit.steps"
                  >
                    <template v-slot:[`item.order`]="{ item }">
                      {{ item.order + 1 }}
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <v-text-field
                        v-model="item.name"
                        :rules="rules"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                      <v-text-field v-model="item.description"></v-text-field>
                    </template>
                    <template v-slot:[`item.componentId`]="{ item }">
                      <v-select
                        :items="componentsEdit"
                        v-model="item.componentId"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        size="small"
                        @click="deleteStep(itemToEdit, item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageEditInterviewTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullEditInterviewTypeInside ? 'success' : 'error'
                "
                :icon="
                  successfullEditInterviewTypeInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditInterviewTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editInterviewTypeDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!editingInterviewType">
              Update
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteInterviewTypeDialog"
      max-width="550"
      min-height="550"
    >
      <v-card>
        <v-card-title>
          <h5>Delete Interview Type</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected interview type? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteInterviewTypeDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingInterviewType">
            <v-btn @click="deleteInterviewType" class="ok-modal">
              Confirm
            </v-btn>
          </template>
          <v-btn @click="deleteInterviewType" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsInterviewTypeDialog" max-width="800">
      <v-card>
        <v-form ref="detailsInterviewTypeForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Description:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.description }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Analysis Type:</h6>
                </v-col>
                <v-col cols="9">
                  <p
                    class="mb-0"
                    v-if="itemToShow.interviewAnalysisType !== undefined"
                  >
                    {{ itemToShow.interviewAnalysisType.name }}
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  itemToShow.steps !== undefined && itemToShow.steps.length == 0
                "
              >
                <v-col cols="2">
                  <h6 class="details-subtitles">Steps:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">Interview type with no associated steps</p>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12" class="pb-0">
                  <h6 class="details-subtitles">Steps:</h6>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table
                    :headers="headersDetails"
                    :items="itemToShow.steps"
                    item-key="itemToShow.id"
                  >
                    <template v-slot:[`item.order`]="{ item }">
                      {{ item.order + 1 }}
                    </template></v-data-table
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsInterviewTypeDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import InterviewsDataService from "@/services/InterviewsDataService";
import ComponentsDataService from "@/services/ComponentsDataService";

export default {
  name: "InterviewTypesList",
  data() {
    return {
      interviewTypes: [],
      successfullInterviewTypes: false,
      messageInterviewTypes: "",
      componentsCreate: [],
      componentsEdit: [],
      successfullComponents: false,
      messageComponents: "",
      analysisTypesCreate: [],
      analysisTypesEdit: [],
      successfullAnalysisTypes: false,
      messageAnalysisTypes: "",
      loading: false,
      successfullCreateInterviewType: false,
      messageCreateInterviewType: "",
      successfullCreateInterviewTypeInside: false,
      messageCreateInterviewTypeInside: "",
      successfullEditInterviewType: false,
      messageEditInterviewType: "",
      successfullEditInterviewTypeInside: false,
      messageEditInterviewTypeInside: "",
      successfullDeleteInterviewType: false,
      messageDeleteInterviewType: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Steps", value: "steps.length" },
        { text: "Analysis Type", value: "interviewAnalysisType.name" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalPages: 1,
        totalItems: 0,
      },
      headersSteps: [
        { text: "Step", value: "order" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Component", value: "componentId" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      interviewType: {
        name: "",
        description: "",
        interviewAnalysisTypeId: null,
        steps: [],
      },
      rules: [(v) => !!v || "Required field"],
      createInterviewTypeDialog: false,
      creatingInterviewType: false,
      editInterviewTypeDialog: false,
      editingInterviewType: false,
      deleteInterviewTypeDialog: false,
      deletingInterviewType: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      headersDetails: [
        { text: "Step", value: "order" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Component", value: "component.label" },
      ],
      showDetailsInterviewTypeDialog: false,

      pageAnalysisTypesCreate: 1,
      totalItemsAnalysisTypesCreate: 0,
      itemsPerPageAnalysisTypesCreate: 10,
      loadingAnalysisTypesCreate: false,
      isMenuOpenAnalysisTypesCreate: false,

      pageComponentsCreate: 1,
      totalItemsComponentsCreate: 0,
      itemsPerPageComponentsCreate: 10,
      loadingComponentsCreate: false,
      isMenuOpenComponentsCreate: false,
    };
  },
  async mounted() {
    await this.getAllInterviewAnalysisTypesEdit();
    await this.getAllComponents();
    await this.getAllInterviewTypes(1);
  },
  methods: {
    getAllInterviewTypes(page) {
      this.loading = true;

      InterviewsDataService.findAllInterviewTypes(
        page,
        this.pagination.itemsPerPage
      ).then(
        (response) => {
          if (response.status == 200) {
            this.interviewTypes = response.data.data;

            if (this.interviewTypes.length !== 0) {
              this.pagination.page = page;
              this.pagination.totalPages = response.data.links.totalPages || 1;

              this.pagination.totalItems =
                response.data.links.totalItems ||
                this.pagination.totalPages * this.pagination.itemsPerPage;
            }
          } else {
            this.successfullInterviewTypes = false;
            this.messageInterviewTypes =
              "An error has occurred retrieving the list of interview types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullInterviewTypes = false;
          this.messageInterviewTypes =
            "An error has occurred retrieving the list of interview types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    handlePageChange(newPage) {
      console.log("handlePageChange: ", newPage);
      this.pagination.page = newPage;
      this.getAllInterviewTypes(newPage);
    },
    getAllComponents() {
      this.loading = true;

      ComponentsDataService.findAll(-1, 0).then(
        (response) => {
          if (response.status == 200) {
            this.componentsEdit = response.data.data;
            this.componentsCreate = response.data.data;
          } else {
            this.successfullComponents = false;
            this.messageComponents =
              "An error has occurred retrieving the list of components. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullComponents = false;
          this.messageComponents =
            "An error has occurred retrieving the list of components. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    observeMenuCreateAnalysisTypes() {
      const menu = this.$refs.menuAnalysisTypeCreate;
      if (!menu) return;

      const observer = new MutationObserver(() => {
        this.$nextTick(() => {
          const dropdown = menu.$el.querySelector(".v-menu__content");

          if (dropdown && !this.isMenuOpenAnalysisTypesCreate) {
            console.log("Menú abierto");
            this.onOpenAnalysisTypesCreate(true);
            this.isMenuOpenAnalysisTypesCreate = true; // Marca el menú como abierto
            dropdown.addEventListener(
              "scroll",
              this.onScrollAnalysisTypesCreate
            );
          } else if (!dropdown && this.isMenuOpenAnalysisTypesCreate) {
            console.log("Menú cerrado");
            this.onOpenAnalysisTypesCreate(false);
            this.isMenuOpenAnalysisTypesCreate = false; // Marca el menú como cerrado
          }
        });
      });

      observer.observe(menu.$el, { childList: true, subtree: true });
    },
    getAllInterviewAnalysisTypesCreate() {
      if (this.loadingAnalysisTypesCreate) return;
      this.loadingAnalysisTypesCreate = true;

      InterviewsDataService.findAllInterviewAnalysisTypes(
        this.pageAnalysisTypesCreate,
        this.itemsPerPageAnalysisTypesCreate
      ).then(
        (response) => {
          if (response.status == 200) {
            if (response.data.data.length === 0) {
              console.log("No hay más datos para cargar");
              this.loadingAnalysisTypesCreate = false;
              return;
            }

            this.analysisTypesCreate = [
              ...this.analysisTypesCreate,
              ...response.data.data,
            ];
            this.totalItemsAnalysisTypesCreate = response.data.links.totalItems;
            this.pageAnalysisTypesCreate++;
          } else {
            this.successfullAnalysisTypes = false;
            this.messageAnalysisTypes =
              "An error has occurred retrieving the list of analysis types. Please, try again later.";
          }

          this.loadingAnalysisTypesCreate = false;
        },
        (error) => {
          this.successfullAnalysisTypes = false;
          this.messageAnalysisTypes =
            "An error has occurred retrieving the list of analysis types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loadingAnalysisTypesCreate = false;
        }
      );
    },
    onOpenAnalysisTypesCreate(isOpen) {
      console.log(
        "Ejecutando onOpenAnalysisTypesCreate, estado del menú:",
        isOpen
      );
      if (isOpen && this.analysisTypesCreate.length === 0) {
        this.getAllInterviewAnalysisTypesCreate();
      }
    },
    onScrollAnalysisTypesCreate(event) {
      const dropdown = event.target;

      if (
        dropdown.scrollTop + dropdown.clientHeight >=
          dropdown.scrollHeight - 10 &&
        !this.loadingAnalysisTypesCreate
      ) {
        console.log("Llamando a getAllAnalysisTypesCreate()");
        this.getAllInterviewAnalysisTypesCreate();
      }
    },
    getAllInterviewAnalysisTypesEdit() {
      this.loading = true;

      InterviewsDataService.findAllInterviewAnalysisTypes(-1, 0).then(
        (response) => {
          if (response.status == 200) {
            this.analysisTypesEdit = response.data.data;
          } else {
            this.successfullAnalysisTypes = false;
            this.messageAnalysisTypes =
              "An error has occurred retrieving the list of analysis types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullAnalysisTypes = false;
          this.messageAnalysisTypes =
            "An error has occurred retrieving the list of analysis types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    /* getAllEnvironmentBackgrounds() {
      this.loading = true;

      EnvironmentBackgroundsDataService.findAll().then(
        (response) => {
          if (response.status == 200) {
            this.environmentBackgrounds = response.data.data;
          } else {
            this.successfullEnvironmentBackgrounds = false;
            this.messageEnvironmentBackgrounds =
              "An error has occurred retrieving the list of environment backgrounds. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullEnvironmentBackgrounds = false;
          this.messageEnvironmentBackgrounds =
            "An error has occurred retrieving the list of environment backgrounds. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    }, */
    openCreateInterviewType() {
      this.createInterviewTypeDialog = true;

      this.$nextTick(() => {
        this.observeMenuCreateAnalysisTypes();
      });
    },
    addStepCreate(interType) {
      interType.steps.push({
        name: "",
        description: "",
        order: interType.steps.length,
        componentId: null,
      });
    },
    addStepEdit(interType) {
      interType.steps.push({
        name: "",
        description: "",
        order: interType.steps.length,
        componentId: this.componentsEdit[0].id,
      });
    },
    deleteStep(interType, step) {
      interType.steps.splice(step.order, 1);

      for (let i = 0; i < interType.steps.length; i++) {
        interType.steps[i].order = i;
      }
    },
    createInterviewType() {
      this.successfullCreateInterviewType = true;
      this.messageCreateInterviewType = "";

      if (this.$refs.createInterviewTypeForm.validate()) {
        if (
          this.interviewType.steps.length > 0 &&
          this.interviewType.interviewAnalysisTypeId !== null
        ) {
          this.creatingInterviewType = true;

          if (this.interviewType.description.trim() == "")
            this.interviewType.description = null;

          InterviewsDataService.createInterviewType(this.interviewType).then(
            (response) => {
              if (response.status == 201) {
                this.getAllInterviewTypes(1);

                this.messageCreateInterviewType = "";
                this.successfullCreateInterviewType = true;

                setTimeout(() => {
                  this.messageCreateInterviewType =
                    "Interview Type successfully created.";
                }, 10);
              } else {
                this.messageCreateInterviewType = "";
                this.successfullCreateInterviewType = true;

                setTimeout(() => {
                  this.messageCreateInterviewType =
                    "An error has occurred creating the interview type. Please, try again later.";
                }, 10);
              }

              this.createInterviewTypeDialog = false;
              this.creatingInterviewType = false;
              this.interviewType = {
                name: "",
                description: "",
                interviewAnalysisTypeId: null,
                steps: [],
              };
            },
            (error) => {
              this.messageCreateInterviewTypeInside = "";
              this.messageCreateInterviewType = "";

              if (error.response.status === 409) {
                this.successfullCreateInterviewTypeInside = false;

                setTimeout(() => {
                  this.messageCreateInterviewTypeInside =
                    "Interview Type already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullCreateInterviewType = false;

                setTimeout(() => {
                  this.messageCreateInterviewType =
                    "An error has occurred creating the interview type. Please, try again later.";
                }, 10);

                this.createInterviewTypeDialog = false;
                this.interviewType = {
                  name: "",
                  description: "",
                  interviewAnalysisTypeId: null,
                  steps: [],
                };
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.creatingInterviewType = false;
            }
          );
        } else {
          this.messageCreateInterviewTypeInside = "";
          this.successfullCreateInterviewTypeInside = false;

          setTimeout(() => {
            this.messageCreateInterviewTypeInside = "Please, fill all fields.";
          }, 10);
        }
      }
    },
    cancelCreateInterviewType() {
      this.createInterviewTypeDialog = false;
      this.interviewType = {
        name: "",
        description: "",
        interviewAnalysisTypeId: null,
        steps: [],
      };
    },
    openEditInterviewType(interviewType) {
      this.itemToEdit = { ...interviewType };

      this.itemToEdit.interviewAnalysisTypeId =
        this.itemToEdit.interviewAnalysisType.id;

      this.itemToEdit.steps.forEach((step) => {
        step.componentId = step.component.id;
      });

      this.editInterviewTypeDialog = true;
    },
    editInterviewType() {
      this.successfullEditInterviewType = true;

      if (this.$refs.editInterviewTypeForm.validate()) {
        if (
          this.itemToEdit.steps.length > 0 &&
          this.itemToEdit.interviewAnalysisTypeId !== null
        ) {
          this.editingInterviewType = true;

          if (this.itemToEdit.description.trim() == "")
            this.itemToEdit.description = null;

          InterviewsDataService.updateInterviewType(this.itemToEdit).then(
            (response) => {
              if (response.status == 204) {
                this.getAllInterviewTypes(1);
                this.messageEditInterviewType = "";
                this.successfullEditInterviewType = true;

                setTimeout(() => {
                  this.messageEditInterviewType =
                    "Interview Type successfully updated.";
                }, 10);
              } else {
                this.messageEditInterviewType = "";
                this.successfullEditInterviewType = true;

                setTimeout(() => {
                  this.messageEditInterviewType =
                    "An error has occurred updating the interview type. Please, try again later.";
                }, 10);
              }

              this.editInterviewTypeDialog = false;
              this.editingInterviewType = false;
              this.itemToEdit = {};
            },
            (error) => {
              this.messageEditInterviewTypeInside = "";
              this.messageEditInterviewType = "";

              if (error.response.status === 409) {
                this.successfullEditInterviewTypeInside = false;

                setTimeout(() => {
                  this.messageEditInterviewTypeInside =
                    "Interview Type already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullEditInterviewType = false;

                setTimeout(() => {
                  this.messageEditInterviewType =
                    "An error has occurred updating the interview type. Please, try again later.";
                }, 10);

                this.editInterviewTypeDialog = false;
                this.itemToEdit = {};
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.editingInterviewType = false;
            }
          );
        } else {
          this.messageEditInterviewTypeInside = "";
          this.successfullEditInterviewTypeInside = false;

          setTimeout(() => {
            this.messageEditInterviewTypeInside = "Please, fill all fields.";
          }, 10);
        }
      }
    },
    openDeleteInterviewType(interviewType) {
      this.itemToDelete = { ...interviewType };
      this.deleteInterviewTypeDialog = true;
    },
    deleteInterviewType() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingInterviewType = true;

        InterviewsDataService.deleteInterviewType(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllInterviewTypes(1);
              this.messageDeleteInterviewType = "";
              this.successfullDeleteInterviewType = true;

              setTimeout(() => {
                this.messageDeleteInterviewType =
                  "Interview Type successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteInterviewType = "";
              this.successfullDeleteInterviewType = false;

              setTimeout(() => {
                this.messageDeleteInterviewType =
                  "An error has occurred deleting the interview type. Please, try again later.";
              }, 10);
            }

            this.deleteInterviewTypeDialog = false;
            this.deletingInterviewType = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteInterviewType = "";
            this.successfullDeleteInterviewType = false;

            setTimeout(() => {
              this.messageDeleteInterviewType =
                "An error has occurred deleting the interview type. Please, try again later.";
            }, 10);

            this.deleteInterviewTypeDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingInterviewType = false;
          }
        );
      } else {
        this.messageDeleteInterviewType = "";
        this.successfullDeleteInterviewType = false;

        setTimeout(() => {
          this.messageDeleteInterviewType =
            "An error has occurred deleting the interview type. Please, try again later.";
        }, 10);

        this.deleteInterviewTypeDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(interviewType) {
      this.itemToShow = { ...interviewType };
      this.showDetailsInterviewTypeDialog = true;
    },
  },
};
</script>
