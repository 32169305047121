<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Users</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2" v-if="canRegisterUsers">
                      <v-btn
                        @click="openRegisterForm"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Register new user
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="users"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait"
                  multi-sort
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.itemsPerPage"
                  :page.sync="pagination.page"
                  @update:page="handlePageChange"
                >
                  <template v-slot:[`item.username`]="{ item }">
                    {{ item.username }}
                  </template>
                  <template v-slot:[`item.email`]="{ item }">
                    {{ item.email }}
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              v-if="messageRegisterSucceed"
              outlined
              :color="successfullRegisterSucceed ? 'success' : 'error'"
              :icon="
                successfullRegisterSucceed ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageRegisterSucceed }}
            </v-alert>
            <v-alert
              class="mt-4"
              v-if="messageUsers"
              outlined
              :color="successfullUsers ? 'success' : 'error'"
              :icon="successfullUsers ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageUsers }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="registerUserDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="registerUserForm" @submit.prevent="register">
          <v-card-title>
            <h5>Register User</h5>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.username"
                    label="Username"
                    :rules="[usernameRules.required]"
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    :rules="[emailRules.required, emailRules.email]"
                    type="email"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                  <v-text-field v-model="user.password" label="Password"
                    :rules="[passwordRules.required, passwordRules.minLength]" type="password"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="user.confirmPassword" label="Repeat password" :rules="[
                    confirmPasswordRules.required,
                    confirmPasswordRules.passwordMatch,
                  ]" type="password"></v-text-field>
                </v-col> -->
              </v-row>
              <v-alert
                v-if="messageSignUp"
                outlined
                :color="successfullSignUp ? 'success' : 'error'"
                :icon="successfullSignUp ? 'mdi-check-circle' : 'mdi-alert'"
                dismissible
                class="mx-2 mb-0"
              >
                {{ messageSignUp }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelRegisterUser" class="cancel-modal">
              Cancel
            </v-btn>

            <v-btn
              type="submit"
              class="ok-modal"
              v-if="!signingUp"
              :disabled="!isValidSignUp"
            >
              Sign up user
            </v-btn>
            <v-btn color="primary" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UsersDataService from "@/services/UsersDataService";
import AuthDataService from "@/services/AuthDataService";

export default {
  name: "UsersList",
  data() {
    return {
      users: [],
      successfullUsers: false,
      messageUsers: "",
      successfullRegisterSucceed: false,
      messageRegisterSucceed: "",
      successfullSignUp: false,
      messageSignUp: "",
      loading: false,
      headers: [
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Created at", value: "createdAt" },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalPages: 1,
        totalItems: 0,
      },
      registerUserDialog: false,
      user: {
        username: "",
        email: "",
        // password: "",
        // confirmPassword: "",
      },
      signingUp: false,
      emailRules: {
        required: (v) => !!v || "Required field",
        email: (v) => /.+@.+\..+/.test(v) || "Invalid email address",
      },
      usernameRules: {
        required: (v) => !!v || "Required field",
      },
      // passwordRules: {
      //   required: (v) => !!v || "Required field",
      //   minLength: (v) => v.length > 7 || "Min. length 8 characters",
      // },
      // confirmPasswordRules: {
      //   required: (v) => !!v || "Required field",
      //   passwordMatch: (v) =>
      //     v === this.user.password || "Passwords do not match",
      // },
      canRegisterUsers: true,
    };
  },
  computed: {
    isValidSignUp() {
      return (
        this.user.email !== "" &&
        this.user.username !== "" &&
        // this.user.password !== "" &&
        // this.user.confirmPassword !== "" &&
        this.emailRules.email(this.user.email) !== "Invalid email address"
        // this.passwordRules.minLength(this.user.password) !==
        //   "Min. length 8 characters" &&
        // this.confirmPasswordRules.passwordMatch(this.user.confirmPassword) !==
        //   "Passwords do not match"
      );
    },
  },
  watch: {},
  async mounted() {
    this.canRegisterUsers = JSON.parse(
      localStorage.getItem("user")
    ).canRegisterUsers;

    await this.getAllUsers(1);
  },
  methods: {
    getAllUsers(page) {
      this.loading = true;

      UsersDataService.findAll(page, this.pagination.itemsPerPage).then(
        (response) => {
          if (response.status == 200) {
            this.users = response.data.data;

            if (this.users.length !== 0) {
              this.pagination.page = page;
              this.pagination.totalPages = response.data.links.totalPages || 1;

              this.pagination.totalItems =
                response.data.links.totalItems ||
                this.pagination.totalPages * this.pagination.itemsPerPage;
            }
          } else {
            this.successfullUsers = false;
            this.messageUsers =
              "An error has occurred retrieving the list of users. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullUsers = false;
          this.messageUsers =
            "An error has occurred retrieving the list of users. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    handlePageChange(newPage) {
      console.log("handlePageChange: ", newPage);
      this.pagination.page = newPage;
      this.getAllUsers(newPage);
    },
    openRegisterForm() {
      this.user.username = "";
      this.user.email = "";
      // this.user.password = "";
      // this.user.confirmPassword = "";
      this.registerUserDialog = true;
    },
    cancelRegisterUser() {
      this.user.username = "";
      this.user.email = "";
      // this.user.password = "";
      // this.user.confirmPassword = "";
      this.registerUserDialog = false;
    },
    register() {
      console.log("register");
      this.messageSignUp = "";
      this.successfullSignUp = false;
      this.messageRegisterSucceed = "";
      this.successfullRegisterSucceed = false;

      // if (this.user.password !== this.user.confirmPassword) {
      //   this.successfullSignUp = false;
      //   this.messageSignUp = "Passwords do not match.";
      // } else {
      this.signingUp = true;

      AuthDataService.register(
        this.user.email,
        this.user.username
        // this.user.password
      ).then(
        (response) => {
          console.log("response register: ", response);
          if (response.status == 201) {
            this.getAllUsers(1);
            this.registerUserDialog = false;
            this.successfullRegisterSucceed = true;
            this.messageRegisterSucceed =
              "Sign up successfully completed. Please tell the user to check his or her email inbox to confirm the account and login.";
          } else {
            this.successfullSignUp = false;
            this.messageSignUp =
              "An error occurred while signing up. Please, try again later.";
          }

          this.signingUp = false;
        },
        (error) => {
          this.successfullSignUp = false;
          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status == 409) {
            if (error.response.data.error == "EMAIL_ALREADY_EXISTS") {
              this.messageSignUp =
                "Email already in use. Please, provide another one.";
            } else if (error.response.data.error == "USERNAME_ALREADY_EXISTS") {
              this.messageSignUp =
                "Username already in use. Please, provide another one.";
            } else {
              this.messageSignUp =
                "An error occurred while signing up. Please, try again later.";
            }
          } else if (error.response.status == 403) {
            this.messageSignUp =
              "You do not have permission to register users.";
          } else if (error.response.status === 401) {
            localStorage.removeItem("user");
            this.$router.push("/");
          } else {
            this.messageSignUp =
              "An error occurred while signing up. Please, try again later.";
          }

          this.signingUp = false;
        }
      );
      // }
    },
  },
};
</script>
