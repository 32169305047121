<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-alert
                  class="mt-4"
                  v-if="message"
                  outlined
                  :color="successfull ? 'success' : 'error'"
                  :icon="successfull ? 'mdi-check-circle' : 'mdi-alert'"
                  dismissible
                >
                  {{ message }}
                </v-alert>
                <v-card-title>
                  <v-row v-if="!editing">
                    <v-col class="col col-11">
                      <h1>Cv Information</h1>
                    </v-col>
                    <v-col class="col col-1">
                      <v-btn
                        class="mr-4 mb-2"
                        @click="openEdit"
                        color="primary"
                        block
                      >
                        Edit
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col class="col col-10">
                      <h1>Cv Information</h1>
                    </v-col>
                    <v-col class="col col-1">
                      <v-btn
                        class="mr-2 mb-2"
                        @click="cancelEdit"
                        color="secondary"
                        block
                      >
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col class="col col-1">
                      <v-btn
                        class="mr-4 mb-2"
                        @click="editInformation"
                        color="primary"
                        block
                      >
                        Save changes
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="1">
                      <v-img
                        :aspect-ratio="1"
                        class="bg-white"
                        :src="require('@/assets/user.svg')"
                        width="100"
                      />
                      <!-- <v-img
                        v-else
                        :aspect-ratio="1"
                        class="bg-white"
                        :src="
                          'https://d1wsk4j49gqdtn.cloudfront.net/' +
                          personalInformation.image
                        "
                        width="100"
                      /> -->
                    </v-col>
                    <v-col cols="10">
                      <p
                        v-if="
                          username !== null &&
                          username !== undefined &&
                          username !== ''
                        "
                      >
                        Candidate: {{ username }}
                      </p>
                      <p v-else>Candidate: -</p>
                      <!-- <p v-if="personalInformation.name !== null">
                        Name: {{ personalInformation.name }}
                      </p>
                      <p v-else>Name: N/D</p>
                      <p v-if="personalInformation.email !== null">
                        Email: {{ personalInformation.email }}
                      </p>
                      <p v-else>Email: N/D</p>
                      <p v-if="personalInformation.phone !== null">
                        Phone: {{ personalInformation.phone }}
                      </p>
                      <p v-else>Phone: N/D</p> -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>

            <template v-if="!editing">
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0">
                    <h1>Academic information</h1>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card-text class="pb-0">
                        <v-data-table
                          :headers="headersEducation"
                          :items="education"
                          multi-sort
                          :options="{ itemsPerPage: 5 }"
                          v-if="education.length > 0"
                        >
                          <template v-slot:[itemTitle]="{ item }">
                            <template v-if="item.title != undefined">
                              {{ item.title }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemInstitution]="{ item }">
                            <template v-if="item.institution != undefined">
                              {{ item.institution }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemDate]="{ item }">
                            <template v-if="item.date != undefined">
                              {{ item.date }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                        </v-data-table>
                        <p v-else>No academic information</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0">
                    <h1>Work experience</h1>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card-text class="pb-0">
                        <v-data-table
                          :headers="headersWorkExperience"
                          :items="workExperience"
                          multi-sort
                          :options="{ itemsPerPage: 5 }"
                          v-if="workExperience.length > 0"
                        >
                          <template v-slot:[itemPosition]="{ item }">
                            <template v-if="item.position != undefined">
                              {{ item.position }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemCompany]="{ item }">
                            <template v-if="item.company != undefined">
                              {{ item.company }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemPlace]="{ item }">
                            <template v-if="item.place != undefined">
                              {{ item.place }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemFunctions]="{ item }">
                            <template v-if="item.functions != undefined">
                              <v-card-text class="d-flex align-center">
                                {{ item.functions.length }}
                                <v-btn
                                  icon
                                  class="ml-2 more-info-btn"
                                  :ripple="false"
                                  @click="openFunctions(item)"
                                >
                                  <v-icon size="20" color="primary"
                                    >mdi-information</v-icon
                                  >
                                </v-btn>
                              </v-card-text>
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemDuration]="{ item }">
                            <template v-if="item.duration != undefined">
                              {{ item.duration }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemDate]="{ item }">
                            <template v-if="item.date != undefined">
                              {{ item.date }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                        </v-data-table>
                        <p v-else>No work experience</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0">
                    <h1>Certifications</h1>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card-text class="pb-0">
                        <v-data-table
                          :headers="headersCertifications"
                          :items="certifications"
                          multi-sort
                          :options="{ itemsPerPage: 5 }"
                          v-if="certifications.length > 0"
                        >
                          <template v-slot:[itemTitleCertifications]="{ item }">
                            <template v-if="item.title != undefined">
                              {{ item.title }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template
                            v-slot:[itemInstitutionCertifications]="{ item }"
                          >
                            <template v-if="item.institution != undefined">
                              {{ item.institution }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                          <template v-slot:[itemDateCertifications]="{ item }">
                            <template v-if="item.date != undefined">
                              {{ item.date }}
                            </template>
                            <template v-else>
                              <p>-</p>
                            </template>
                          </template>
                        </v-data-table>
                        <p v-else>No information about certifications</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0 mb-4">
                    <h1>Skills</h1>
                  </v-card-title>
                  <v-row class="mb-4">
                    <v-col cols="12" class="py-0">
                      <v-card-text class="py-0">
                        <v-row v-if="skills.length > 0">
                          <v-col
                            class="col col-4 py-0"
                            v-for="(skill, index) in skills"
                            :key="index"
                          >
                            <v-list color="transparent" class="list-container">
                              <v-list-item
                                class="list-items-class"
                                v-if="skill.name !== null"
                              >
                                <v-list-item-icon
                                  class="py-0 mb-0 mr-2 list-items-icon-class"
                                >
                                  <v-icon>mdi-circle-small</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="py-0">
                                  <v-list-item-title v-if="skill.level !== null"
                                    >{{ skill.name }} (Level:
                                    {{ skill.level }})</v-list-item-title
                                  ><v-list-item-title v-else>{{
                                    skill.name
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <p class="ml-3">No information about skills</p>
                        </v-row>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0">
                    <h1>Academic information</h1>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card-text class="pb-0">
                        <v-data-table
                          :headers="headersEducationEdit"
                          :items="itemToEdit.education"
                          multi-sort
                          :options="{ itemsPerPage: 5 }"
                          v-if="itemToEdit.education.length > 0"
                        >
                          <template v-slot:[itemTitle]="{ item }">
                            <v-text-field v-model="item.title"></v-text-field>
                          </template>
                          <template v-slot:[itemInstitution]="{ item }">
                            <v-text-field
                              v-model="item.institution"
                            ></v-text-field>
                          </template>
                          <template v-slot:[itemDate]="{ item }">
                            <v-text-field v-model="item.date"></v-text-field>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  @click="openDeleteItem('education', item)"
                                >
                                  <v-icon size="20" color="error"
                                    >mdi-delete</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                        <p v-else>No academic information</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0">
                    <h1>Work experience</h1>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card-text class="pb-0">
                        <v-data-table
                          :headers="headersWorkExperienceEdit"
                          :items="itemToEdit.work_experience"
                          multi-sort
                          :options="{ itemsPerPage: 5 }"
                          v-if="itemToEdit.work_experience.length > 0"
                        >
                          <template v-slot:[itemPosition]="{ item }">
                            <v-text-field
                              v-model="item.position"
                            ></v-text-field>
                          </template>
                          <template v-slot:[itemCompany]="{ item }">
                            <v-text-field v-model="item.company"></v-text-field>
                          </template>
                          <template v-slot:[itemPlace]="{ item }">
                            <v-text-field v-model="item.place"></v-text-field>
                          </template>
                          <template v-slot:[itemDuration]="{ item }">
                            <v-text-field
                              v-model="item.duration"
                            ></v-text-field>
                          </template>
                          <template v-slot:[itemDate]="{ item }">
                            <v-text-field v-model="item.date"></v-text-field>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  @click="
                                    openDeleteItem('workExperience', item)
                                  "
                                >
                                  <v-icon size="20" color="error"
                                    >mdi-delete</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                        <p v-else>No work experience</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0">
                    <h1>Certifications</h1>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card-text class="pb-0">
                        <v-data-table
                          :headers="headersCertificationsEdit"
                          :items="itemToEdit.certificates"
                          multi-sort
                          :options="{ itemsPerPage: 5 }"
                          v-if="itemToEdit.certificates.length > 0"
                        >
                          <template v-slot:[itemTitleCertifications]="{ item }">
                            <v-text-field v-model="item.title"></v-text-field>
                          </template>
                          <template
                            v-slot:[itemInstitutionCertifications]="{ item }"
                          >
                            <v-text-field
                              v-model="item.institution"
                            ></v-text-field>
                          </template>
                          <template v-slot:[itemDateCertifications]="{ item }">
                            <v-text-field
                              v-model="item.date"
                            ></v-text-field> </template
                          ><template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  @click="
                                    openDeleteItem('certifications', item)
                                  "
                                >
                                  <v-icon size="20" color="error"
                                    >mdi-delete</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                        <p v-else>No information about certifications</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col col-12">
                  <v-card-title class="pt-0">
                    <h1>Skills</h1>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card-text class="pb-0">
                        <v-data-table
                          :headers="headersSkillsEdit"
                          :items="itemToEdit.tools_and_skills"
                          multi-sort
                          :options="{ itemsPerPage: 5 }"
                          v-if="itemToEdit.tools_and_skills.length > 0"
                        >
                          <template v-slot:[itemNameSkills]="{ item }">
                            <v-text-field v-model="item.name"></v-text-field>
                          </template>
                          <template v-slot:[itemLevelSkills]="{ item }">
                            <v-text-field v-model="item.level"></v-text-field>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  @click="openDeleteItem('skills', item)"
                                >
                                  <v-icon size="20" color="error"
                                    >mdi-delete</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                        <p v-else>No information about skills</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
        <v-dialog v-model="showFunctions" max-width="550" min-height="550">
          <v-card>
            <v-card-title>
              <h5>Functions</h5>
            </v-card-title>
            <v-card-text
              v-if="
                itemToShow.functions !== null &&
                itemToShow.functions !== undefined
              "
              class="pb-0"
            >
              <v-list color="transparent">
                <v-list-item
                  v-for="(func, index) in itemToShow.functions"
                  :key="index"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <p class="mb-0">{{ func }}</p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn text @click="showFunctions = false" class="ok-modal">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="550" min-height="550">
          <v-card>
            <v-card-title>
              <h5>Delete item</h5>
            </v-card-title>
            <v-card-text class="pb-0">
              <p class="mb-0">
                Do you really want to delete the selected item? <br />
                If so, press Confirm.
              </p>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn text @click="deleteDialog = false" class="cancel-modal">
                Cancel
              </v-btn>
              <v-btn @click="deleteItem" class="ok-modal"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CandidatesDataService from "@/services/CandidatesDataService";

export default {
  name: "CVResults",
  data() {
    return {
      message: "",
      successfull: false,
      // personalInformation: {
      //   image: null,
      // },
      education: [],
      workExperience: [],
      certifications: [],
      skills: [],
      languages: [],
      headersEducation: [
        { text: "Title", value: "title" },
        { text: "Institution", value: "institution" },
        { text: "Date", value: "date" },
      ],
      headersEducationEdit: [
        { text: "Title", value: "title" },
        { text: "Institution", value: "institution" },
        { text: "Date", value: "date" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersCertifications: [
        { text: "Title", value: "title" },
        { text: "Institution", value: "institution" },
        { text: "Date", value: "date" },
      ],
      headersCertificationsEdit: [
        { text: "Title", value: "title" },
        { text: "Institution", value: "institution" },
        { text: "Date", value: "date" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersWorkExperience: [
        { text: "Position", value: "position" },
        { text: "Company", value: "company" },
        { text: "City", value: "place" },
        { text: "Functions", value: "functions" },
        { text: "Duration", value: "duration" },
        { text: "Date", value: "date" },
      ],
      headersWorkExperienceEdit: [
        { text: "Position", value: "position" },
        { text: "Company", value: "company" },
        { text: "City", value: "place" },
        { text: "Duration", value: "duration" },
        { text: "Date", value: "date" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersSkillsEdit: [
        { text: "Name", value: "name" },
        { text: "Level", value: "level" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      itemToShow: {},
      showFunctions: false,
      username: "",
      editing: false,
      rules: [(v) => !!v || "Required field"],
      itemToEdit: {
        education: [],
        work_experience: [],
        certificates: [],
        tools_and_skills: [],
      },
      type: "",
      itemToDelete: {},
      deleteDialog: false,
    };
  },
  watch: {
    "$route.params.cv": {
      handler(newCv) {
        if (newCv) {
          this.getCV();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    itemTitle() {
      return `item.title`;
    },
    itemInstitution() {
      return `item.institution`;
    },
    itemDate() {
      return `item.date`;
    },
    itemTitleCertifications() {
      return `item.title`;
    },
    itemInstitutionCertifications() {
      return `item.institution`;
    },
    itemDateCertifications() {
      return `item.date`;
    },
    itemPosition() {
      return `item.position`;
    },
    itemCompany() {
      return `item.company`;
    },
    itemPlace() {
      return `item.place`;
    },
    itemFunctions() {
      return `item.functions`;
    },
    itemDuration() {
      return `item.duration`;
    },
    itemNameSkills() {
      return `item.name`;
    },
    itemLevelSkills() {
      return `item.level`;
    },
  },
  mounted() {
    this.getCV();
  },
  methods: {
    getCV() {
      if (
        this.$route.params.cv !== undefined &&
        this.$route.params.cv !== null
      ) {
        this.username = this.$route.params.cv.username;
        CandidatesDataService.getCV(this.$route.params.cv.cvId).then(
          (response) => {
            if (response.status == 200) {
              console.log("response.data.data: ", response.data.data);

              this.education = [];
              this.workExperience = [];
              this.skills = [];
              this.certifications = [];

              // this.personalInformation.name =
              //   response.data.data["personal_information"]["Name"] ||
              //   response.data.data["personal_information"]["name"] ||
              //   null;
              //   this.personalInformation.email =
              //     response.data.data["personal_information"]["email"] || null;
              //   this.personalInformation.links =
              //     response.data.data["personal_information"]["links"] || null;
              //   this.personalInformation.phone =
              //     response.data.data["personal_information"]["phone"] || null;
              //   if (
              //     this.personalInformation.phone !== null &&
              //     Array.isArray(this.personalInformation.phone)
              //   )
              //     this.personalInformation.phone =
              //       this.personalInformation.phone[0];
              //   this.personalInformation.image =
              //     response.data.data.image_path || null;

              if (
                response.data.data["education"] !== undefined &&
                response.data.data["education"] !== null &&
                response.data.data["education"] !== "No information available"
              ) {
                response.data.data["education"].forEach((item) => {
                  let education = {};
                  education.date = item["date"] || null;
                  education.institution = item["academic_institution"] || null;
                  education.title = item["academic_title"] || null;

                  this.education.push(education);
                });
              }

              if (
                response.data.data["work_experience"] !== undefined &&
                response.data.data["work_experience"] !== null &&
                response.data.data["work_experience"] !==
                  "No information available"
              ) {
                response.data.data["work_experience"].forEach((item) => {
                  let job = {};
                  job.position = item["position"] || null;
                  job.functions = item["functions"] || null;
                  job.company = item["company"] || null;
                  job.date = item["date"] || null;
                  job.place = item["city_country"] || null;
                  job.duration = item["duration"] || null;

                  this.workExperience.push(job);
                });
              }

              if (
                response.data.data["tools_and_skills"] !== undefined &&
                response.data.data["tools_and_skills"] !== null &&
                response.data.data["tools_and_skills"] !==
                  "No information available"
              ) {
                response.data.data["tools_and_skills"].forEach((item) => {
                  let skill = {};

                  if (
                    item["level"] !== null &&
                    item["level"] !== undefined &&
                    item["level"] !== ""
                  )
                    skill.level = item["level"];
                  else skill.level = null;

                  if (
                    item["skill"] !== null &&
                    item["skill"] !== undefined &&
                    item["skill"] !== ""
                  )
                    skill.name = item["skill"];
                  else if (
                    item["lang"] !== null &&
                    item["lang"] !== undefined &&
                    item["lang"] !== ""
                  )
                    skill.name = item["lang"];
                  else skill.name = null;

                  if (skill.name !== null) this.skills.push(skill);
                });
              }

              if (
                response.data.data["certificates"] !== undefined &&
                response.data.data["certificates"] !== null &&
                response.data.data["certificates"] !==
                  "No information available" &&
                response.data.data["certificates"].length > 0
              ) {
                response.data.data["certificates"].forEach((item) => {
                  let certification = {};

                  certification.date = item["date"] || null;
                  certification.institution =
                    item["academic_institution"] || null;
                  certification.title = item["academic_title"] || null;

                  this.certifications.push(certification);
                });
              }

              // console.log(
              //   "this.personalInformation: ",
              //   this.personalInformation
              // );
              console.log("this.education: ", this.education);
              console.log("this.workExperience: ", this.workExperience);
              console.log("this.skills: ", this.skills);
              console.log("this.certifications: ", this.certifications);
            } else {
              this.successfull = false;
              this.message =
                "An error has occurred retrieving CV information. Please, try again later.";
            }
          },
          (error) => {
            this.successfull = false;
            this.message =
              "An error has occurred retrieving CV information. Please, try again later.";
            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }
          }
        );
      } else {
        this.$router.push("/candidates");
      }
    },
    openFunctions(item) {
      this.showFunctions = true;
      this.itemToShow = { ...item };
      console.log("itemToShow: ", this.itemToShow);
    },
    openEdit() {
      this.itemToEdit = {
        education: [...this.education],
        work_experience: [...this.workExperience],
        certificates: [...this.certifications],
        tools_and_skills: [...this.skills],
      };

      this.editing = true;
    },
    openDeleteItem(type, item) {
      this.type = type;
      this.itemToDelete = { ...item };
      this.deleteDialog = true;
    },
    deleteItem() {
      if (this.type == "skills") {
        const index = this.itemToEdit.tools_and_skills.findIndex((item) =>
          this.deepEqual(item, this.itemToDelete)
        );

        if (index !== -1) this.itemToEdit.tools_and_skills.splice(index, 1);
      } else if (this.type == "certifications") {
        const index = this.itemToEdit.certificates.findIndex((item) =>
          this.deepEqual(item, this.itemToDelete)
        );

        if (index !== -1) this.itemToEdit.certificates.splice(index, 1);
      } else if (this.type == "education") {
        const index = this.itemToEdit.education.findIndex((item) =>
          this.deepEqual(item, this.itemToDelete)
        );

        if (index !== -1) this.itemToEdit.education.splice(index, 1);
      } else if (this.type == "workExperience") {
        const index = this.itemToEdit.work_experience.findIndex((item) =>
          this.deepEqual(item, this.itemToDelete)
        );

        if (index !== -1) this.itemToEdit.work_experience.splice(index, 1);
      }

      this.deleteDialog = false;
    },
    cancelEdit() {
      this.successfull = true;
      this.message = "";
      this.getCV();
      this.editing = false;
    },
    editInformation() {
      console.log("Edit information: ", this.itemToEdit);

      if (this.itemToEdit.education.length > 0)
        this.itemToEdit.education.forEach((item) => {
          item["academic_title"] = item["title"];
          item["academic_institution"] = item["institution"];
          delete item["title"];
          delete item["institution"];
        });

      if (this.itemToEdit.certificates.length > 0)
        this.itemToEdit.certificates.forEach((item) => {
          item["academic_title"] = item["title"];
          item["academic_institution"] = item["institution"];
          delete item["title"];
          delete item["institution"];
        });

      if (this.itemToEdit.work_experience.length > 0)
        this.itemToEdit.work_experience.forEach((item) => {
          item["city_country"] = item["place"];
          delete item["place"];
        });

      if (this.itemToEdit.tools_and_skills.length > 0)
        this.itemToEdit.tools_and_skills.forEach((item) => {
          item["skill"] = item["name"];
          delete item["name"];
        });

      console.log("itemToEdit: ", this.itemToEdit);

      CandidatesDataService.updateCV(
        this.$route.params.cv.cvId,
        this.itemToEdit
      ).then(
        (response) => {
          if (response.status == 204) {
            console.log("response: ", response);
            this.getCV();
            this.message = "CV information has been updated successfully.";
            this.successfull = true;
          } else {
            this.successfull = false;
            this.message =
              "An error has occurred updating CV information. Please, try again later.";
          }
          this.editing = false;
        },
        (error) => {
          this.successfull = false;
          this.message =
            "An error has occurred updating CV information. Please, try again later.";
          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
          this.editing = false;
        }
      );
    },
    deepEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
  },
};
</script>
