import http from "../http-common";

const API_URL_PATH = "components/";
const API_URL_PATH_WITHOUT_BAR = "components";
const API_URL_PATH_INFO = "utils/components";
// const API_URL_PATH_TYPES = "components-type/";
// const API_URL_PATH_TYPES_WITHOUT_BAR = "components-type";

class ComponentsDataService {
  findAll(page, limit) {
    return http.get(
      API_URL_PATH_WITHOUT_BAR + "?page=" + page + "&limit=" + limit
    );
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(component) {
    return http.post(API_URL_PATH, component);
  }

  update(component) {
    return http.put(API_URL_PATH + component.id, component);
  }

  delete(id) {
    return http.delete(API_URL_PATH + id);
  }

  findAllComponentTypes() {
    return http.get(API_URL_PATH_TYPES);
  }

  findAllComponentTypes() {
    return http.get(API_URL_PATH_INFO);
  }

  /* findOneComponentType(id) {
    return http.get(API_URL_PATH_TYPES + id);
  }

  createComponentType(type) {
    return http.post(API_URL_PATH_TYPES, type);
  }

  updateComponentType(type) {
    return http.put(API_URL_PATH_TYPES + type.id, type);
  }

  deleteComponentType(id) {
    return http.delete(API_URL_PATH_TYPES + id);
  } */
}

export default new ComponentsDataService();
