<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>AI Dialogue</h1>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-row class="center-content">
                  <v-col cols="8" class="py-0">
                    <v-alert v-if="profiler === null" outlined type="info" color="info">
                      Please assign a virtual profiler to your user in the
                      "Virtual Profiler Management" menu option, located in the
                      main menu.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row class="center-content">
                  <v-col cols="8" class="py-0">
                    <v-alert v-if="isError" outlined :color="'error'" :icon="'mdi-alert'" dismissible>
                      {{ errorMessage }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="center-content" v-if="!conversationId">
                  <v-col cols="8" class="py-0 mb-4">
                    <v-btn @click="createConversation" class="ok-modal" :disabled="profiler === null">
                      <v-icon class="mr-2">mdi-phone-plus</v-icon>New
                      conversation
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="center-content" v-else>
                  <v-col cols="8" class="py-0 mb-4">
                    <v-btn @click="createConversation" class="ok-modal">
                      <v-icon class="mr-2">mdi-restart</v-icon>Reset
                      conversation
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="center-content" v-if="conversationId">
                  <v-col cols="8" class="py-0">
                    <v-select ref="menuPositions" label="Position *" :items="positions" v-model="request.positionId"
                      item-text="name" item-value="id" :rules="rules" :disabled="receivedContextsResponse"></v-select>
                  </v-col>
                  <v-col cols="8" class="py-0">
                    <v-select ref="menuInterviewAnalysisTypes" label="Interview Analysis Type *"
                      :items="interviewAnalysisTypes" v-model="request.interviewAnalysisTypeId" item-text="name"
                      item-value="id" :rules="rules" :disabled="receivedContextsResponse"></v-select>
                  </v-col>
                  <v-col cols="8" class="py-0 mt-2" v-if="receivedContextsResponse && receivedInitialPrompt">
                    <v-radio-group v-model="sendType" row>
                      <v-radio label="Send audio" value="audio"></v-radio>
                      <v-radio label="Send text" value="text"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="8" class="py-0 mt-2"
                    v-if="receivedContextsResponse && receivedInitialPrompt && sendType === 'text'">
                    <v-textarea label="Question *" v-model="request.question" :rules="rules"></v-textarea>
                  </v-col>

                  <v-col cols="8" class="py-0 mt-2 audio-recorder"
                    v-if="receivedContextsResponse && receivedInitialPrompt && sendType === 'audio'">
                    <v-btn @click="toggleRecording" class="ok-modal" :class="{ recording: isRecording }"><v-icon
                        class="mr-2" v-if="isRecording">mdi-record-circle-outline</v-icon>
                      <v-icon class="mr-2" v-else>mdi-record-rec</v-icon>{{
                        isRecording ? "Recording..." : "Start recording"
                      }}</v-btn>
                    <v-btn @click="clearAudio" v-if="request.audioUrl" class="ml-4 error-btn"
                      :class="{ recording: isRecording }"><v-icon class="mr-2">mdi-trash-can</v-icon>Clear
                      audio
                    </v-btn>
                    <v-radio-group v-model="speechEngine">
                      <v-radio label="Google" value="google"></v-radio>
                      <v-radio label="Whisper" value="whisper"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="8" class="py-0 center-content">
                    <v-btn @click="sendRequest" class="ok-modal" v-if="!creatingRequest" :disabled="(receivedContextsResponse &&
                      sendType === 'text' &&
                      request.question.trim() == '') ||
                      (receivedContextsResponse &&
                        sendType === 'audio' &&
                        !request.audioUrl) ||
                      (!receivedContextsResponse &&
                        (request.positionId === null ||
                          request.interviewAnalysisTypeId === null))
                      ">
                      Send
                    </v-btn>
                    <v-btn @click="sendRequest" class="ok-modal" v-else disabled>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="existsAnswer" class="center-content">
                  <v-col cols="8">
                    <h4>Answer:</h4>
                  </v-col>
                  <v-col cols="8" class="py-0">
                    <v-textarea v-model="answer" disabled v-if="answer.trim() !== ''"></v-textarea>
                    <p v-else>No precise answer could be obtained.</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-alert class="mt-4" v-if="messagePositions" outlined :color="successfullPositions ? 'success' : 'error'"
              :icon="successfullPositions ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
              {{ messagePositions }}
            </v-alert>

            <v-alert class="mt-4" v-if="messageInterviewAnalysisTypes" outlined
              :color="successfullInterviewAnalysisTypes ? 'success' : 'error'" :icon="successfullInterviewAnalysisTypes
                ? 'mdi-check-circle'
                : 'mdi-alert'
                " dismissible>
              {{ messageInterviewAnalysisTypes }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PositionsDataService from "@/services/PositionsDataService";
import InterviewsDataService from "@/services/InterviewsDataService";
import SocketioService from "@/services/socketioService.js";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

export default {
  name: "AIDialogueView",
  data() {
    return {
      positions: [],
      successfullPositions: false,
      messagePositions: "",
      interviewAnalysisTypes: [],
      successfullInterviewAnalysisTypes: false,
      messageInterviewAnalysisTypes: "",
      creatingRequest: false,
      rules: [(v) => !!v || "Required field"],
      request: {
        positionId: null,
        interviewAnalysisTypeId: null,
        question: "",
        audioUrl: null,
      },
      loading: false,

      pagePositions: 1,
      totalItemsPositions: 0,
      itemsPerPagePositions: 10,
      loadingPositions: false,
      isMenuOpenPositions: false,

      pageInterviewAnalysisTypes: 1,
      totalItemsInterviewAnalysisTypes: 0,
      itemsPerPageInterviewAnalysisTypes: 10,
      loadingInterviewAnalysisTypes: false,
      isMenuOpenInterviewAnalysisTypes: false,

      existsAnswer: false,
      answer: "",
      conversationId: undefined,

      errorMessage: "",
      isError: false,

      receivedContextsResponse: false,
      receivedInitialPrompt: false,

      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      isAudio: true,
      sendType: "text",
      speechEngine: "google",
      profiler: null,

      ffmpeg: new FFmpeg({ log: true }),
    };
  },
  async mounted() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     if (this.$refs.menuPositions) {
    //       this.observeMenuPositions();
    //       this.observeMenuInterviewAnalysisTypes();
    //     } else {
    //       console.error("menuPositions ref is still undefined after timeout");
    //     }
    //   }, 500);
    // });
    if (!this.ffmpeg.loaded)
      await this.ffmpeg.load()
    await this.getAllInterviewAnalysisTypes();
    await this.getAllPositions();

    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user: ", user);
    this.profiler = user.profilerId;
    console.log("this.profiler: ", this.profiler);

    this.conversationId = localStorage.getItem("conversationId");
    this.request.positionId = JSON.parse(localStorage.getItem("positionId"));
    this.request.interviewAnalysisTypeId = JSON.parse(
      localStorage.getItem("interviewAnalysisTypeId")
    );

    console.log("this.request: ", this.request);
    console.log(
      "localStorage.getItem('positionId'): ",
      localStorage.getItem("positionId")
    );
    if (
      this.request.positionId !== null &&
      this.request.positionId !== undefined &&
      this.request.interviewAnalysisTypeId !== null &&
      this.request.interviewAnalysisTypeId !== undefined
    )
      this.receivedContextsResponse = true;
  },
  created() {
    SocketioService.emisor.$on("authenticateResponse", async (data) => {
      if (!data.success) {
        this.isError = true;
        this.errorMessage =
          "Error authenticating the user connection. Please, contact an administrator.";
      } else {
        this.isError = false;
        this.errorMessage = "";
      }
    });

    SocketioService.emisor.$on("dialogueConversationResponse", async (data) => {
      if (data.error === undefined || data.error.message === undefined) {
        this.isError = false;
        this.errorMessage = "";

        this.conversationId = data.conversationId;
        this.request = {
          question: "",
          audioUrl: null,
          positionId: null,
          interviewAnalysisTypeId: null,
        };

        localStorage.setItem("conversationId", data.conversationId);
        localStorage.setItem("positionId", this.request.positionId);
        localStorage.setItem(
          "interviewAnalysisTypeId",
          this.request.interviewAnalysisTypeId
        );
        this.receivedContextsResponse = false;
      } else {
        this.isError = true;
        this.errorMessage =
          "Error creating the conversation. Please, contact an administrator.";
      }
    });

    SocketioService.emisor.$on(
      "dialoguePartialMsgResponse",
      async (data) => { }
    );

    SocketioService.emisor.$on("dialogCompleteMsgResponse", async (data) => {
      this.answer = data.data;
      this.existsAnswer = true;
      this.creatingRequest = false;
      this.request.question = "";
      this.request.audioUrl = null;
      this.receivedInitialPrompt = true;
    });

    SocketioService.emisor.$on("dialogContextsResponse", async (data) => {
      console.log("en la respuesta: ", data);
      this.receivedContextsResponse = true;
      this.creatingRequest = false;
      localStorage.setItem("positionId", this.request.positionId);
      localStorage.setItem(
        "interviewAnalysisTypeId",
        this.request.interviewAnalysisTypeId
      );

      console.log("this.request en la respuesta: ", this.request);
    });
  },
  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    this.request = {
      positionId: null,
      interviewAnalysisTypeId: null,
      question: "",
      audioUrl: null,
    };

    this.stopRecording();

    localStorage.setItem("positionId", null);
    localStorage.setItem("interviewAnalysisTypeId", null);
    localStorage.removeItem("conversationId");
    SocketioService.deleteConversation(this.$socket);
    next();
  },
  watch: {
    sendType() {
      this.request.question = "";
      this.request.audioUrl = null;
    },
  },
  methods: {
    /* observeMenuPositions() {
      console.log("observeMenuPositions");
      const menu = this.$refs.menuPositions;
      console.log("menu: ", menu);
      if (!menu) return;

      const observer = new MutationObserver(() => {
        this.$nextTick(() => {
          const dropdown = menu.$el.querySelector(".v-menu__content");

          if (dropdown && !this.isMenuOpenPositions) {
            console.log("Menú de posiciones abierto");
            this.onOpenPositions(true);
            this.isMenuOpenPositions = true; // Marca el menú como abierto
            dropdown.addEventListener("scroll", this.onScrollPositions);
          } else if (!dropdown && this.isMenuOpenPositions) {
            console.log("Menú de posiciones cerrado");
            this.onOpenPositions(false);
            this.isMenuOpenPositions = false; // Marca el menú como cerrado
          }
        });
      });

      observer.observe(menu.$el, { childList: true, subtree: true });
    }, */
    getAllPositions() {
      PositionsDataService.findAll(-1, 0).then(
        (response) => {
          if (response.status == 200) {
            this.positions = response.data.data;
          } else {
            this.successfullPositions = false;
            this.messagePositions =
              "An error has occurred retrieving the list of positions. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullPositions = false;
          this.messagePositions =
            "An error has occurred retrieving the list of positions. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    /* onOpenPositions(isOpen) {
      console.log("Ejecutando onOpenPositions, estado del menú:", isOpen);
      if (isOpen && this.positions.length === 0) {
        this.getAllPositions();
      }
    },
    onScrollPositions(event) {
      const dropdown = event.target;

      if (
        dropdown.scrollTop + dropdown.clientHeight >=
          dropdown.scrollHeight - 10 &&
        !this.loadingPositions
      ) {
        console.log("Llamando a getAllPositions()");
        this.getAllPositions();
      }
    },
    observeMenuInterviewAnalysisTypes() {
      console.log("observeMenuInterviewAnalysisTypes");
      const menu = this.$refs.menuInterviewAnalysisTypes;
      if (!menu) return;
      const observer = new MutationObserver(() => {
        this.$nextTick(() => {
          const dropdown = menu.$el.querySelector(".v-menu__content");

          if (dropdown && !this.isMenuOpenInterviewAnalysisTypes) {
            console.log("Menú de análisis de entrevistas abierto");
            this.onOpenInterviewAnalysisTypes(true);
            this.isMenuOpenInterviewAnalysisTypes = true; // Marca el menú como abierto
            dropdown.addEventListener(
              "scroll",
              this.onScrollInterviewAnalysisTypes
            );
          } else if (!dropdown && this.isMenuOpenInterviewAnalysisTypes) {
            console.log("Menú de análisis de entrevistas cerrado");
            this.onOpenInterviewAnalysisTypes(false);
            this.isMenuOpenInterviewAnalysisTypes = false; // Marca el menú como cerrado
          }
        });
      });

      observer.observe(menu.$el, { childList: true, subtree: true });
    }, */
    getAllInterviewAnalysisTypes() {
      InterviewsDataService.findAllInterviewAnalysisTypes(-1, 0).then(
        (response) => {
          if (response.status == 200) {
            this.interviewAnalysisTypes = response.data.data;
          } else {
            this.successfullInterviewAnalysisTypes = false;
            this.messageInterviewAnalysisTypes =
              "An error has occurred retrieving the list of interview analysis types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullInterviewAnalysisTypes = false;
          this.messageInterviewAnalysisTypes =
            "An error has occurred retrieving the list of interview analysis types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    /* onOpenInterviewAnalysisTypes(isOpen) {
      console.log(
        "Ejecutando onOpenInterviewAnalysisTypes, estado del menú:",
        isOpen
      );
      if (isOpen && this.interviewAnalysisTypes.length === 0) {
        this.getAllInterviewAnalysisTypes();
      }
    },
    onScrollInterviewAnalysisTypes(event) {
      const dropdown = event.target;

      if (
        dropdown.scrollTop + dropdown.clientHeight >=
          dropdown.scrollHeight - 10 &&
        !this.loadingInterviewAnalysisTypes
      ) {
        console.log("Llamando a getAllInterviewAnalysisTypes()");
        this.getAllInterviewAnalysisTypes();
      }
    }, */
    async createConversation() {
      let user = JSON.parse(localStorage.getItem("user"));
      await SocketioService.deleteConversation(this.$socket);
      await SocketioService.createConversation(
        this.$socket,
        user.profilerId,
        null
      );
      this.existsAnswer = false;
      this.receivedInitialPrompt = false;
      this.answer = "";
    },
    async sendRequest() {
      this.creatingRequest = true;
      this.existsAnswer = false;

      if (this.receivedContextsResponse) {
        if (this.sendType === "audio") {
          console.log("sendAudio: ", this.request);
          if (!this.request.audioUrl) return;
          const reader = new FileReader()
          reader.onloadend = () => {
            const res = reader.result
            SocketioService.sendAudio(this.$socket, res, this.speechEngine);
          }
          reader.readAsArrayBuffer(this.request.audioUrl)
        } else {
          console.log("sendRequest: ", this.request);
          SocketioService.sendMessage(this.$socket, this.request.question);
        }
      } else {
        console.log("sendContext: ", this.request);
        SocketioService.sendContext(
          this.$socket,
          this.request.positionId,
          this.request.interviewAnalysisTypeId
        );
      }
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
          try {
            if (!this.ffmpeg.loaded)
              await this.ffmpeg.load()

            await this.ffmpeg.writeFile('input.opus', await fetchFile(audioBlob));

            await this.ffmpeg.exec([
              '-i', 'input.opus',
              '-ar', '16000',
              '-ac', '1',
              '-c:a', 'pcm_s16le',
              'output.wav'
            ]);
            const data = await this.ffmpeg.readFile('output.wav');
            const wavBlob = new Blob([data], { type: "audio/wav" });
            this.request.audioUrl = wavBlob;
          } catch (error) {
            console.error("Error converting audio: ", error);
          }
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error("Error accessing the microphone :", error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
    clearAudio() {
      this.request.audioUrl = null;
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
  },
};
</script>
