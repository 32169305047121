class UserMediaController {
  userMediaOptions = {
    video: true,
    audio: true,
  };

  userMediaStream = null;

  displayMediaOptions = {
    video: true,
    audio: true,
  };

  displayMediaStream = null;

  placeholderCanvas = null;

  Initialize() {
    const placeholderCanvas = document.createElement("canvas");
    placeholderCanvas.className = "placeholder-canvas";
    document.body.appendChild(placeholderCanvas);
    this.placeholderCanvas = placeholderCanvas;
  }

  isUserMediaSupported() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return true;
    }
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      // Camera not supported
      return false;
    }

    return true;
  }

  isDisplayMediaSupported() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
      // Screen share not supported
      return false;
    }

    return true;
  }

  stopStream(stream) {
    try {
      stream.stop();
    } catch (ex) {
      undefined;
    }
    try {
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    } catch (ex) {
      undefined;
    }
  }

  getPlaceholderStream() {
    if (!this.placeholderCanvas) return null;
    else {
      this.placeholderCanvas.getContext("2d");
      return this.placeholderCanvas.captureStream(25);
    }
  }

  getMedia(reqFunc, audio, video, callback) {
    if (!audio && !video) {
      return callback(null);
    }
    let options = {
      audio: audio,
      video: video,
    };

    // First we try both
    reqFunc(
      options,
      function (stream) {
        callback(stream);
      },
      function (err) {
        console.error(err);
        if (err.name === "NotAllowedError") {
          return callback(null, err.message);
        }

        // Now we try only audio
        options = {
          audio: audio,
          video: false,
        };
        reqFunc(
          options,
          function (stream) {
            callback(stream);
          },
          function (err2) {
            console.error(err2);
            if (err2.name === "NotAllowedError") {
              return callback(null, err2.message);
            }

            // Now we try only video
            options = {
              audio: false,
              video: video,
            };
            reqFunc(
              options,
              function (stream) {
                callback(stream);
              },
              function (err3) {
                console.error(err3);
                if (err3.name === "NotAllowedError") {
                  return callback(null, err3.message);
                }

                // No way to get the stream
                try {
                  callback(UserMediaController.getPlaceholderStream());
                } catch (ex) {
                  callback(null);
                }
              }
            );
          }
        );
      }
    );
  }

  getUserMedia(cb) {
    const reqFunc = function (options, callback, errCallback) {
      try {
        navigator.mediaDevices
          .getUserMedia(options)
          .then(callback)
          .catch(errCallback);
      } catch (ex) {
        try {
          navigator.mediaDevices
            .getUserMedia(options) // Deprecated
            .then(callback)
            .catch(errCallback);
        } catch (ex2) {
          errCallback(ex2);
        }
      }
    };

    this.getMedia(
      reqFunc,
      this.userMediaOptions.audio,
      this.userMediaOptions.video,
      function (stream) {
        cb(stream);
      }.bind(this)
    );
  }

  getDisplayMedia(cb) {
    const reqFunc = function (options, callback, errCallback) {
      try {
        navigator.mediaDevices
          .getDisplayMedia(options)
          .then(callback)
          .catch(errCallback);
      } catch (ex) {
        errCallback(ex);
      }
    };

    this.getMedia(
      reqFunc,
      this.displayMediaOptions.audio,
      this.displayMediaOptions.video,
      function (stream, ex) {
        cb(stream, ex);
      }.bind(this)
    );
  }

  checkMediaStreamVideo(stream) {
    if (!stream) return false;
    const tracks = stream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind === "video") {
        return true;
      }
    }
    return false;
  }

  checkMediaStreamAudio(stream) {
    if (!stream) return false;
    const tracks = stream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind === "audio") {
        return true;
      }
    }
    return false;
  }

  setMediaStreamVideoEnabled(stream, enabled) {
    if (!stream) return;
    const tracks = stream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind === "video") {
        tracks[i].enabled = !!enabled;
      }
    }
  }

  setMediaStreamAudioEnabled(stream, enabled) {
    if (!stream) return;
    const tracks = stream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind === "audio") {
        tracks[i].enabled = !!enabled;
      }
    }
  }

  detectStreamEnding(stream, handler) {
    const tracks = stream.getTracks();
    if (tracks.length > 0) {
      tracks[0].addEventListener("ended", handler);
    }
  }
}

export default new UserMediaController();
