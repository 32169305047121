<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Virtual Profilers</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateProfiler"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create virtual profiler
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateProfiler"
                  outlined
                  :color="successfullCreateProfiler ? 'success' : 'error'"
                  :icon="
                    successfullCreateProfiler ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateProfiler }}
                </v-alert>
                <v-alert
                  v-if="messageAssignProfiler"
                  outlined
                  :color="successfullAssignProfiler ? 'success' : 'error'"
                  :icon="
                    successfullAssignProfiler ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageAssignProfiler }}
                </v-alert>
                <v-alert
                  v-if="messageUnassignProfiler"
                  outlined
                  :color="successfullUnassignProfiler ? 'success' : 'error'"
                  :icon="
                    successfullUnassignProfiler
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageUnassignProfiler }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="profilers"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.itemsPerPage"
                  :page.sync="pagination.page"
                  @update:page="handlePageChange"
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-if="
                        user.profilerId !== null && item.id === user.profilerId
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="unassignProfiler(item)">
                          <v-icon size="20" color="red"
                            >mdi-account-remove-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Unassign me virtual profiler</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="assignProfiler(item)"
                          :disabled="someAssigned"
                        >
                          <v-icon size="20" color="primary"
                            >mdi-account-plus-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Assign me virtual profiler</span>
                    </v-tooltip>

                    <!-- <v-tooltip
                      top
                      v-if="
                        user.profilerId !== null && item.id === user.profilerId
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openUnassignProfiler(item)"
                        >
                          <v-icon size="20" color="red"
                            >mdi-account-remove-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Unassign me virtual profiler</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openAssignProfiler(item)"
                          :disabled="someAssigned"
                        >
                          <v-icon size="20" color="primary"
                            >mdi-account-plus-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Assign me virtual profiler</span>
                    </v-tooltip> -->
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-alert
              class="mt-4"
              v-if="messageProfilers"
              outlined
              :color="successfullProfilers ? 'success' : 'error'"
              :icon="successfullProfilers ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageProfilers }}
            </v-alert>
            <v-alert
              class="mt-4"
              v-if="messageVoices"
              outlined
              :color="successfullVoices ? 'success' : 'error'"
              :icon="successfullVoices ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageVoices }}
            </v-alert>
            <v-alert
              class="mt-4"
              v-if="messageOptions"
              outlined
              :color="successfullOptions ? 'success' : 'error'"
              :icon="successfullOptions ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageOptions }}
            </v-alert>
            <!-- <v-alert
              class="mt-4"
              v-if="messageProfilerTypes"
              outlined
              :color="successfullProfilerTypes ? 'success' : 'error'"
              :icon="
                successfullProfilerTypes ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageProfilerTypes }}
            </v-alert> -->
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createProfilerDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createProfilerForm" @submit.prevent="createProfiler">
          <v-card-title>
            <h5>Create Virtual Profiler</h5>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="profiler.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Description *"
                    v-model="profiler.description"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="System prompt *"
                    v-model="profiler.systemPrompt"
                    :rules="rules"
                    rows="5"
                    no-resize
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    :items="options.sttObjs"
                    item-text="name"
                    item-value="id"
                    label="STT API *"
                    v-model="profiler.STTapi"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    :items="options.nlgObjs"
                    item-text="name"
                    item-value="id"
                    label="NLG API *"
                    v-model="profiler.NLGapi"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0" v-if="profiler.NLGapi !== null">
                  <v-select
                    :items="options.nlgModels"
                    item-text="name"
                    item-value="id"
                    label="NLG Model *"
                    v-model="profiler.NLGModel"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    :items="options.ttsObjs"
                    item-text="name"
                    item-value="id"
                    label="TTS API *"
                    v-model="profiler.TTSapi"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    :items="characters"
                    item-text="name"
                    item-value="id"
                    label="Character *"
                    v-model="profiler.character"
                    :rules="rules"
                    :disabled="!profiler.TTSapi"
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateProfilerInside"
                class="mb-0 mt-8"
                outlined
                :color="successfullCreateProfilerInside ? 'success' : 'error'"
                :icon="
                  successfullCreateProfilerInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateProfilerInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateProfiler" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!creatingProfiler">
              Create
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsProfilerDialog" max-width="800">
      <v-card>
        <v-form ref="detailsProfilerForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Description:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.description }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">System Prompt:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.systemPrompt }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">STT API:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.STTapi }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">NLG API:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.NLGapi }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">NLG Model:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.NLGModel }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">TTS API:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.TTSapi }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Character:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.character }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsProfilerDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="assignProfilerDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="assignProfilerForm" @click="assignProfiler">
          <v-card-title>
            <h5>Assign Virtual Profiler</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <!-- <v-col cols="12" class="py-0">
                  <v-select
                    :items="profilerTypes"
                    item-text="name"
                    item-value="id"
                    label="Virtual profiler type *"
                    v-model="profilerType"
                    :rules="rules"
                  ></v-select>
                </v-col> -->

                <v-col cols="12" class="py-0">
                  <label>File *</label>
                  <VueFileAgent
                    ref="vueFileAgent"
                    :theme="'list'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :disabled="fileReceived"
                    :accept="'.zip'"
                    :maxSize="'10MB'"
                    :editable="false"
                    :helpText="'Select .zip files to upload the animation files'"
                    :errorText="{
                      type: 'Wrong file extension. Please upload only .zip files.',
                    }"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    v-model="fileRecords"
                  ></VueFileAgent>
                </v-col>
              </v-row>

              <v-alert
                v-if="messageAssignProfilerInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullAssignProfilerInside ? 'success' : 'error'"
                :icon="
                  successfullAssignProfilerInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageAssignProfilerInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="assignProfilerDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn type="submit" class="ok-modal" v-if="!assigningProfiler">
              Update
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unassignProfilerDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Unassign virtual profiler</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to unassign the interviewer's virtual profiler?
            <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="unassignProfilerDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!unassigningProfiler">
            <v-btn @click="unassignProfiler" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="unassignProfiler" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VirtualProfilersDataService from "@/services/VirtualProfilersDataService";

export default {
  name: "VirtualProfilersList",
  data() {
    return {
      profilers: [],
      successfullProfilers: false,
      messageProfilers: "",
      voices: [],
      successfullVoices: false,
      messageVoices: "",
      options: [],
      successfullOptions: false,
      messageOptions: "",
      // profilerTypes: [],
      // successfullProfilerTypes: false,
      // messageProfilerTypes: "",
      characters: [],
      profilerType: null,
      fileReceived: false,
      fileRecords: [],
      fileRecordsForUpload: [],
      uploadDisactive: true,
      loading: false,
      successfullCreateProfiler: false,
      messageCreateProfiler: "",
      successfullCreateProfilerInside: false,
      messageCreateProfilerInside: "",
      successfullAssignProfiler: false,
      messageAssignProfiler: "",
      successfullAssignProfilerInside: false,
      messageAssignProfilerInside: "",
      successfullUnassignProfiler: false,
      messageUnassignProfiler: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "STT API", value: "STTapi" },
        { text: "NLG API", value: "NLGapi" },
        { text: "NLG Model", value: "NLGModel" },
        { text: "TTS API", value: "TTSapi" },
        { text: "Character", value: "character" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalPages: 1,
        totalItems: 0,
      },
      profiler: {
        name: "",
        description: "",
        systemPrompt: "",
        STTapi: null,
        NLGapi: null,
        TTSapi: null,
        character: null,
      },
      rules: [(v) => !!v || "Required field"],
      createProfilerDialog: false,
      creatingProfiler: false,
      assignProfilerDialog: false,
      assigningProfiler: false,
      unassignProfilerDialog: false,
      unassigningProfiler: false,
      itemToAssign: {},
      itemToShow: {},
      showDetailsProfilerDialog: false,
      someAssigned: false,
      user: {},
    };
  },
  watch: {
    "profiler.TTSapi"() {
      if (this.profiler.TTSapi !== null) {
        this.characters = this.voices.filter(
          (voice) => voice.service === this.profiler.TTSapi
        );
      } else {
        this.characters = [];
      }
    },
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("this.user en el mounted: ", this.user);
    await this.getAllProfilers(1);
    await this.getAllVoices();
    await this.getOptions();
    // await this.getProfilerTypes();
  },
  methods: {
    getAllProfilers(page) {
      this.loading = true;

      VirtualProfilersDataService.findAll(
        page,
        this.pagination.itemsPerPage
      ).then(
        (response) => {
          if (response.status == 200) {
            this.profilers = response.data.data;

            if (this.user.profilerId !== null) this.someAssigned = true;
            else this.someAssigned = false;

            // this.someAssigned = false;
            // this.profilers.forEach((profiler) => {
            //   if (profiler.isAssigned) {
            //     this.someAssigned = true;
            //   }
            // });

            if (this.profilers.length !== 0) {
              this.pagination.page = page;
              this.pagination.totalPages = response.data.links.totalPages || 1;

              this.pagination.totalItems =
                response.data.links.totalItems ||
                this.pagination.totalPages * this.pagination.itemsPerPage;
            }
          } else {
            this.successfullProfilers = false;
            this.messageProfilers =
              "An error has occurred retrieving the list of virtual profilers. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullProfilers = false;
          this.messageProfilers =
            "An error has occurred retrieving the list of virtual profilers. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    handlePageChange(newPage) {
      console.log("handlePageChange: ", newPage);
      this.pagination.page = newPage;
      this.getAllProfilers(newPage);
    },
    getAllVoices() {
      this.loading = true;

      VirtualProfilersDataService.findAllVirtualProfilerVoices().then(
        (response) => {
          if (response.status == 200) {
            this.voices = response.data.data;
            this.profilers.forEach((profiler) => {
              profiler.character = this.voices.find(
                (voice) => voice.id === profiler.character
              ).name;
            });
          } else {
            this.successfullVoices = false;
            this.messageVoices =
              "An error has occurred retrieving the list of voices. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullVoices = false;
          this.messageVoices =
            "An error has occurred retrieving the list of voices. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getOptions() {
      this.loading = true;

      VirtualProfilersDataService.findFormOptions().then(
        (response) => {
          if (response.status == 200) {
            this.options = response.data;

            this.options.nlgObjs = [];
            this.options.ttsObjs = [];
            this.options.sttObjs = [];

            this.options.nlg.forEach((item) => {
              let obj = {
                id: item,
                name: item,
              };
              this.options.nlgObjs.push(obj);
            });

            this.options.tts.forEach((item) => {
              let obj = {
                id: item,
                name: item,
              };
              this.options.ttsObjs.push(obj);
            });

            this.options.stt.forEach((item) => {
              let obj = {
                id: item,
                name: item,
              };
              this.options.sttObjs.push(obj);
            });

            VirtualProfilersDataService.findProfilerModels().then(
              (response) => {
                if (response.status == 200) {
                  let models = response.data.data;

                  this.options.nlgModels = [];
                  models.forEach((model) => {
                    let obj = {
                      id: model,
                      name: model,
                    };

                    this.options.nlgModels.push(obj);
                  });
                } else {
                  this.successfullOptions = false;
                  this.messageOptions =
                    "An error has occurred retrieving the form options. Please, try again later.";
                }

                this.loading = false;
              },
              (error) => {
                this.successfullOptions = false;
                this.messageOptions =
                  "An error has occurred retrieving the form options. Please, try again later.";

                console.error(
                  (error.response && error.response.data) ||
                    error.message ||
                    error.toString()
                );

                if (
                  error.response.status === 401 ||
                  error.response.status === 403
                ) {
                  localStorage.removeItem("user");
                  this.$router.push("/");
                }

                this.loading = false;
              }
            );
          } else {
            this.successfullOptions = false;
            this.messageOptions =
              "An error has occurred retrieving the form options. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullOptions = false;
          this.messageOptions =
            "An error has occurred retrieving the form options. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    /* getProfilerTypes() {
      this.loading = true;

      VirtualProfilersDataService.findAllVirtualProfilerTypes().then(
        (response) => {
          if (response.status == 200) {
            this.profilerTypes = response.data.data;
          } else {
            this.successfullProfilerTypes = false;
            this.messageProfilerTypes =
              "An error has occurred retrieving the list of profiler types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullProfilerTypes = false;
          this.messageProfilerTypes =
            "An error has occurred retrieving the list of profiler types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    }, */
    openCreateProfiler() {
      this.createProfilerDialog = true;
      this.profiler = {
        name: "",
        description: "",
        systemPrompt: "",
        STTapi: null,
        NLGapi: null,
        TTSapi: null,
        character: null,
      };
    },
    createProfiler() {
      this.successfullCreateProfiler = true;
      this.messageCreateProfiler = "";

      if (this.$refs.createProfilerForm.validate()) {
        this.creatingProfiler = true;

        VirtualProfilersDataService.create(this.profiler).then(
          (response) => {
            if (response.status == 201) {
              this.getAllProfilers(1);

              this.messageCreateProfiler = "";
              this.successfullCreateProfiler = true;

              setTimeout(() => {
                this.messageCreateProfiler =
                  "Virtual profiler successfully created.";
              }, 10);
            } else {
              this.messageCreateProfiler = "";
              this.successfullCreateProfiler = true;

              setTimeout(() => {
                this.messageCreateProfiler =
                  "An error has occurred creating the virtual profiler. Please, try again later.";
              }, 10);
            }

            this.createProfilerDialog = false;
            this.creatingProfiler = false;
            this.profiler = {
              name: "",
              description: "",
              systemPrompt: "",
              STTapi: null,
              NLGapi: null,
              TTSapi: null,
              character: null,
            };
          },
          (error) => {
            this.messageCreateProfilerInside = "";
            this.messageCreateProfiler = "";

            if (error.response.status === 409) {
              this.successfullCreateProfilerInside = false;

              setTimeout(() => {
                this.messageCreateProfilerInside =
                  "Virtual profiler already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullCreateProfiler = false;

              setTimeout(() => {
                this.messageCreateProfiler =
                  "An error has occurred creating the virtual profiler. Please, try again later.";
              }, 10);

              this.createProfilerDialog = false;
              this.profiler = {
                name: "",
                description: "",
                systemPrompt: "",
                STTapi: null,
                NLGapi: null,
                TTSapi: null,
                character: null,
              };
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.creatingProfiler = false;
          }
        );
      }
    },
    cancelCreateProfiler() {
      this.createProfilerDialog = false;
      this.profiler = {
        name: "",
        description: "",
        systemPrompt: "",
        STTapi: null,
        NLGapi: null,
        TTSapi: null,
        character: null,
      };
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      if (validFileRecords.length > 0) {
        this.uploadDisactive = false;
        this.fileRecordsForUpload =
          this.fileRecordsForUpload.concat(validFileRecords);
      } else this.uploadDisactive = true;
    },
    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else this.$refs.vueFileAgent.deleteFileRecord(fileRecord);

      this.uploadDisactive =
        this.fileRecordsForUpload.length <= 0 ? true : false;
      if (this.fileRecordsForUpload.length <= 0) this.message = "";
    },
    fileDeleted: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);

      if (i !== -1) this.fileRecordsForUpload.splice(i, 1);
      else this.deleteUploadedFile(fileRecord);

      this.uploadDisactive =
        this.fileRecordsForUpload.length <= 0 ? true : false;
      if (this.fileRecordsForUpload.length <= 0) this.message = "";
    },
    openAssignProfiler(profiler) {
      this.itemToAssign = { ...profiler };
      this.assignProfilerDialog = true;
      this.profilerType = null;
      this.fileRecords = [];
    },
    assignProfiler(item) {
      this.successfullAssignProfiler = true;
      this.messageAssignProfiler = "";

      // if (this.$refs.assignProfilerForm.validate()) {
      //   this.assigningProfiler = true;

      //   if (this.fileRecordsForUpload.length > 0) {
      //     let file = this.fileRecordsForUpload[0].file;
      //     let formData = new FormData();
      //     formData.append("profilerType", this.profilerType);
      //     formData.append("file", file, file.name);

      VirtualProfilersDataService.assign(item.id).then(
        async (response) => {
          if (response.status == 200) {
            this.user.profilerId = item.id;
            await localStorage.setItem("user", JSON.stringify(this.user));
            this.user = JSON.parse(localStorage.getItem("user"));

            localStorage.removeItem("conversationId");

            console.log("this.user después de assign: ", this.user);
            this.getAllProfilers(1);

            this.messageAssignProfiler = "";
            this.successfullAssignProfiler = true;

            setTimeout(() => {
              this.messageAssignProfiler =
                "Virtual profiler successfully assigned.";
            }, 10);
          } else {
            this.messageAssignProfiler = "";
            this.successfullAssignProfiler = true;

            setTimeout(() => {
              this.messageAssignProfiler =
                "An error has occurred assigning the virtual profiler. Please, try again later.";
            }, 10);
          }

          this.assignProfilerDialog = false;
          this.assigningProfiler = false;
          this.profilerType = null;
          this.fileRecords = [];
        },
        (error) => {
          this.messageAssignProfiler = "";

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          } else {
            this.successfullAssignProfiler = false;

            setTimeout(() => {
              this.messageAssignProfiler =
                "An error has occurred assigning the virtual profiler. Please, try again later.";
            }, 10);

            this.assignProfilerDialog = false;
            this.profilerType = null;
            this.fileRecords = [];
          }

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          this.assigningProfiler = false;
        }
      );
      //   } else {
      //     this.successfullAssignProfilerInside = false;

      //     setTimeout(() => {
      //       this.messageAssignProfilerInside = "Please, select a file.";
      //     }, 10);

      //     this.assigningProfiler = false;
      //   }
      // }
    },
    cancelAssignProfiler() {
      this.assignProfilerDialog = false;
      this.profilerType = null;
      this.fileRecords = [];
    },
    openUnassignProfiler() {
      this.unassignProfilerDialog = true;
    },
    unassignProfiler() {
      this.unassigningProfiler = true;

      VirtualProfilersDataService.unassign().then(
        async (response) => {
          console.log("aqui en la respuesta: ", response);
          if (response.status == 204) {
            this.user.profilerId = null;
            await localStorage.setItem("user", JSON.stringify(this.user));
            this.user = JSON.parse(localStorage.getItem("user"));

            console.log("this.user después de unassign: ", this.user);
            this.getAllProfilers(1);
            this.messageUnassignProfiler = "";
            this.successfullUnassignProfiler = true;

            setTimeout(() => {
              this.messageUnassignProfiler =
                "Virtual profiler successfully unassigned.";
            }, 10);
          } else {
            this.messageUnassignProfiler = "";
            this.successfullUnassignProfiler = false;

            setTimeout(() => {
              this.messageUnassignProfiler =
                "An error has occurred unassigning the virtual profiler. Please, try again later.";
            }, 10);
          }

          this.unassignProfilerDialog = false;
          this.unassigningProfiler = false;
        },
        (error) => {
          console.log("aqui en el error: ", error);
          this.messageUnassignProfiler = "";
          this.successfullUnassignProfiler = false;

          setTimeout(() => {
            this.messageUnassignProfiler =
              "An error has occurred unassigning the virtual profiler. Please, try again later.";
          }, 10);

          this.unassignProfilerDialog = false;

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.unassigningProfiler = false;
        }
      );
    },
    openShowDetails(profiler) {
      this.itemToShow = { ...profiler };
      this.showDetailsProfilerDialog = true;
    },
  },
};
</script>
