<template>
  <v-app>
    <v-app-bar class="topbar" fixed v-if="
      currentRouteName !== 'landing page' &&
      currentRouteName !== 'reset password' &&
      currentRouteName !== 'activate account' &&
      currentRouteName !== 'waiting room candidate' &&
      currentRouteName !== 'error' &&
      currentRouteName !== 'interview'
    ">
      <v-img src="img/logo-virtualProfiler.png" max-height="60" max-width="90" contain alt="Logo"
        @click="goHome()"></v-img>
      <v-spacer></v-spacer>
      <v-menu v-model="menu" offset-y v-if="notifications.length > 0" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="mr-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon size="20" color="primary" v-on="on">mdi-bell-badge</v-icon>
              </template>
              <span>Notifications</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(notification, index) in notifications" :key="index">
            <v-card class="notification-card" :class="notification.status === 'SUCCESS'
                ? 'notification-success'
                : 'notification-error'
              " elevation="1">
              <v-list-item-content class="px-2">
                <v-row v-if="notification.type === 'CV'">
                  <v-col cols="11">
                    <p v-if="notification.status === 'SUCCESS'" class="mb-0 notification-link"
                      @click="openCV(notification, index)">
                      <b>{{ notification.username }}</b>'s CV has been successfully analysed ({{
                        notification.date
                      }})
                    </p>
                    <p v-else class="mb-0">
                      Error analysing
                      <b>{{
                        notification.username
                          ? notification.username
                          : "Unknown User"
                      }}</b>'s CV ({{ notification.date }})
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <v-icon size="18" :color="notification.status === 'SUCCESS' ? 'green' : 'red'
                      " @click="dismissNotification(index)" class="mr-2">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="11">
                    <template v-if="notification.status === 'SUCCESS'">
                      <p v-if="notification.reason === 'Audio Analyzed'" class="mb-0 notification-link"
                        @click="openInterview(notification.id, index)">
                        The audio of <b>{{ notification.username }}</b>'s interview has been successfully analysed ({{
                          notification.date
                        }})
                      </p>
                      <p v-if="notification.reason === 'Tone Analyzed'" class="mb-0 notification-link"
                        @click="openInterview(notification.id, index)">
                        The tone of <b>{{ notification.username }}</b>'s interview has been successfully analysed ({{
                          notification.date
                        }})
                      </p>
                      <p v-if="notification.reason === 'Video Analyzed'" class="mb-0 notification-link"
                        @click="openInterview(notification.id, index)">
                        The video of <b>{{ notification.username }}</b>'s interview has been successfully analysed ({{
                          notification.date
                        }})
                      </p>
                    </template>
                    <p v-else class="mb-0">
                      Error analysing
                      <b>{{
                        notification.username
                          ? notification.username
                          : "Unknown User"
                      }}</b>'s interview ({{ notification.date }})
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <v-icon size="18" :color="notification.status === 'SUCCESS' ? 'green' : 'red'
                      " @click="dismissNotification(index)" class="mr-2">mdi-close</v-icon>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-card>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon disabled class="mr-1">
            <v-icon size="20" color="primary">mdi-bell</v-icon>
          </v-btn>
        </template>
        <span>Notifications</span>
      </v-tooltip>
      <v-tooltip bottom v-if="currentRouteName !== 'home'">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon class="mr-1" @click="goBack()">
            <v-icon size="20" color="primary">mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon class="mr-1" @click="logout()">
            <v-icon size="20" color="primary">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Log out</span>
      </v-tooltip>
    </v-app-bar>
    <v-main class="main-top">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SocketioService from "@/services/socketioService.js";
import AuthDataService from "./services/AuthDataService";
import Vue from "vue";

export default {
  name: "App.vue",
  data() {
    return {
      notifications: [],
      menu: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    Vue.prototype.$socket = null;

    if (localStorage.getItem("user")) {
      Vue.prototype.$socket = SocketioService.setupSocketConnection(
        JSON.parse(localStorage.getItem("user")).id,
        JSON.parse(localStorage.getItem("user")).username,
        true
      );
    }

    SocketioService.emisor.$on("notificationsSended", (data) => {
      this.notifications = data;

      this.notifications.forEach((notification) => {
        notification.date = this.convertToTimezone(notification.date);
      });
    });

    SocketioService.emisor.$on("notificationsActiveSended", (data) => {
      data.date = this.convertToTimezone(data.date);
      this.notifications.splice(0, 0, data);
      this.menu = true;
    });

    SocketioService.emisor.$on("notificationDismissed", (data) => {
      this.notifications.splice(data, 1);
    });
  },
  methods: {
    dismissNotification(index) {
      SocketioService.dismissNotification(this.$socket, index);
    },
    goHome() {
      this.$router.push("/home");
    },
    goBack() {
      if (
        this.currentRouteName === "jobs list" ||
        this.currentRouteName === "contexts list" ||
        this.currentRouteName === "competencies list" ||
        this.currentRouteName === "positions list" ||
        this.currentRouteName === "interview types list" ||
        // this.currentRouteName === "component types list" ||
        this.currentRouteName === "components list" ||
        this.currentRouteName === "interview analysis types list" ||
        // this.currentRouteName === "environment backgrounds list" ||
        this.currentRouteName === "virtual profilers list" ||
        // this.currentRouteName === "virtual profilers types list" ||
        // this.currentRouteName === "virtual profilers animations list" ||
        this.currentRouteName === "candidates list" ||
        this.currentRouteName === "users list" ||
        this.currentRouteName === "ai dialogue"
      )
        this.$router.push("/home");
      else if (this.currentRouteName === "cv info") this.$router.go(-1);
      else this.$router.replace("/candidates");
    },
    dismissNotification(index) {
      SocketioService.dismissNotification(this.$socket, index);
    },
    logout() {
      AuthDataService.logout().then(
        (response) => {
          console.log("response logout: ", response);
          if (response.status == 204) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        },
        (error) => {
          console.error(
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
          );
        }
      );
    },
    openCV(cv, index) {
      console.log("cv: ", cv);
      cv.cvId = cv.cv_id;
      if (this.$route.name === "cv info") {
        this.$router.replace({ name: "cv info", params: { cv: cv } });
      } else {
        this.$router.push({ name: "cv info", params: { cv: cv } });
      }
      // this.$router.push({ name: "cv info", params: { cv: cv } });
      this.menu = false;
      this.dismissNotification(index);
    },
    convertToTimezone(dateAndHour) {
      const date = new Date(dateAndHour);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localDateObj = new Date(date.toLocaleString("en-US", { timeZone }));

      const formattedDate = `${localDateObj
        .getHours()
        .toString()
        .padStart(2, "0")}:${localDateObj
          .getMinutes()
          .toString()
          .padStart(2, "0")} ${localDateObj
            .getDate()
            .toString()
            .padStart(2, "0")}-${(localDateObj.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${localDateObj.getFullYear().toString().slice(-2)}`;

      return formattedDate;
    },
  },
};
</script>
