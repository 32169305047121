<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Interview Analysis Types</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateInterviewAnalysisType"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create interview analysis type
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateInterviewAnalysisType"
                  outlined
                  :color="
                    successfullCreateInterviewAnalysisType ? 'success' : 'error'
                  "
                  :icon="
                    successfullCreateInterviewAnalysisType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateInterviewAnalysisType }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteInterviewAnalysisType"
                  outlined
                  :color="
                    successfullDeleteInterviewAnalysisType ? 'success' : 'error'
                  "
                  :icon="
                    successfullDeleteInterviewAnalysisType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteInterviewAnalysisType }}
                </v-alert>
                <v-alert
                  v-if="messageEditInterviewAnalysisType"
                  outlined
                  :color="
                    successfullEditInterviewAnalysisType ? 'success' : 'error'
                  "
                  :icon="
                    successfullEditInterviewAnalysisType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditInterviewAnalysisType }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="interviewAnalysisTypes"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.itemsPerPage"
                  :page.sync="pagination.page"
                  @update:page="handlePageChange"
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openEditInterviewAnalysisType(item)"
                        >
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit interview analysis type</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteInterviewAnalysisType(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete interview analysis type</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageInterviewAnalysisTypes"
              outlined
              :color="successfullInterviewAnalysisTypes ? 'success' : 'error'"
              :icon="
                successfullInterviewAnalysisTypes
                  ? 'mdi-check-circle'
                  : 'mdi-alert'
              "
              dismissible
            >
              {{ messageInterviewAnalysisTypes }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageContexts"
              outlined
              :color="successfullContexts ? 'success' : 'error'"
              :icon="successfullContexts ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageContexts }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="createInterviewAnalysisTypeDialog"
      max-width="800"
      persistent
      content-class="no-scroll"
    >
      <v-card class="no-scroll">
        <v-form
          ref="createInterviewAnalysisTypeForm"
          @submit.prevent="createInterviewAnalysisType"
        >
          <v-card-title>
            <h5>Create Interview Analysis Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="interviewAnalysisType.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description"
                    v-model="interviewAnalysisType.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-select
                    ref="menuContextsCreate"
                    label="Contexts"
                    :items="contextsCreate"
                    v-model="interviewAnalysisType.contexts"
                    item-text="name"
                    item-value="id"
                    multiple
                    :loading="loadingContextsCreate"
                    attach
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <p class="information-text">
                    <v-icon size="20" color="primary">mdi-information</v-icon
                    >&nbsp;&nbsp;It is not necessary for you to select any
                    context right now. However, please, note that the order of
                    the contexts will affect their use in the avatar.
                  </p>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateInterviewAnalysisTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullCreateInterviewAnalysisTypeInside
                    ? 'success'
                    : 'error'
                "
                :icon="
                  successfullCreateInterviewAnalysisTypeInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateInterviewAnalysisTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="cancelCreateInterviewAnalysisType"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn
              type="submit"
              class="ok-modal"
              v-if="!creatingInterviewAnalysisType"
            >
              Create
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editInterviewAnalysisTypeDialog"
      max-width="800"
      persistent
    >
      <v-card>
        <v-form
          ref="editInterviewAnalysisTypeForm"
          @submit.prevent="editInterviewAnalysisType"
        >
          <v-card-title>
            <h5>Update Interview Analysis Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description"
                    v-model="itemToEdit.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-select
                    label="Contexts"
                    :items="contextsEdit"
                    v-model="itemToEdit.contexts"
                    item-text="name"
                    item-value="id"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <p class="information-text">
                    <v-icon size="20" color="primary">mdi-information</v-icon
                    >&nbsp;&nbsp;Please, note that the order of the contexts
                    will affect their use in the avatar.
                  </p>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageEditInterviewAnalysisTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullEditInterviewAnalysisTypeInside
                    ? 'success'
                    : 'error'
                "
                :icon="
                  successfullEditInterviewAnalysisTypeInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditInterviewAnalysisTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editInterviewAnalysisTypeDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn
              type="submit"
              class="ok-modal"
              v-if="!editingInterviewAnalysisType"
            >
              Update
            </v-btn>
            <v-btn class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteInterviewAnalysisTypeDialog"
      max-width="550"
      min-height="550"
    >
      <v-card>
        <v-card-title>
          <h5>Delete Interview Analysis Type</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected interview analysis type?
            <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteInterviewAnalysisTypeDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingInterviewAnalysisType">
            <v-btn @click="deleteInterviewAnalysisType" class="ok-modal">
              Confirm
            </v-btn>
          </template>
          <v-btn
            @click="deleteInterviewAnalysisType"
            class="ok-modal"
            v-else
            disabled
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsInterviewAnalysisTypeDialog" max-width="800">
      <v-card>
        <v-form ref="detailsInterviewAnalysisTypeForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Description:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0" v-if="itemToShow.description !== ''">
                    {{ itemToShow.description }}
                  </p>
                  <p class="mb-0" v-else>-</p>
                </v-col>
              </v-row>

              <v-row
                v-if="
                  itemToShow.contexts !== undefined &&
                  itemToShow.contexts.length == 0
                "
              >
                <v-col cols="2">
                  <h6 class="details-subtitles">Contexts:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">
                    Interview analysis type with no associated contexts
                  </p>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12" class="pb-0">
                  <h6 class="details-subtitles">Contexts:</h6>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table
                    :headers="headersDetails"
                    :items="itemToShow.contexts"
                    item-key="itemToShow.id"
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsInterviewAnalysisTypeDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import InterviewsDataService from "@/services/InterviewsDataService";
import ContextsDataService from "@/services/ContextsDataService";

export default {
  name: "InterviewAnalysisTypesList",
  data() {
    return {
      interviewAnalysisTypes: [],
      successfullInterviewAnalysisTypes: false,
      messageInterviewAnalysisTypes: "",
      contextsCreate: [],
      contextsEdit: [],
      successfullContexts: false,
      messageContexts: "",
      loading: false,
      successfullCreateInterviewAnalysisType: false,
      messageCreateInterviewAnalysisType: "",
      successfullCreateInterviewAnalysisTypeInside: false,
      messageCreateInterviewAnalysisTypeInside: "",
      successfullEditInterviewAnalysisType: false,
      messageEditInterviewAnalysisType: "",
      successfullEditInterviewAnalysisTypeInside: false,
      messageEditInterviewAnalysisTypeInside: "",
      successfullDeleteInterviewAnalysisType: false,
      messageDeleteInterviewAnalysisType: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Contexts", value: "contexts.length" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalPages: 1,
        totalItems: 0,
      },
      headersDetails: [
        { text: "Name", value: "name" },
        { text: "Content", value: "content" },
      ],
      interviewAnalysisType: {
        name: "",
        description: "",
        contexts: [],
      },
      rules: [(v) => !!v || "Required field"],
      createInterviewAnalysisTypeDialog: false,
      creatingInterviewAnalysisType: false,
      editInterviewAnalysisTypeDialog: false,
      editingInterviewAnalysisType: false,
      deleteInterviewAnalysisTypeDialog: false,
      deletingInterviewAnalysisType: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      showDetailsInterviewAnalysisTypeDialog: false,

      pageContextsCreate: 1,
      totalItemsContextsCreate: 0,
      itemsPerPageContextsCreate: 10,
      loadingContextsCreate: false,
      isMenuOpenCreate: false,
    };
  },
  async mounted() {
    await this.getAllContextsEdit();
    await this.getAllInterviewAnalysisTypes(1);
  },
  methods: {
    getAllInterviewAnalysisTypes(page) {
      this.loading = true;

      InterviewsDataService.findAllInterviewAnalysisTypes(
        page,
        this.pagination.itemsPerPage
      ).then(
        (response) => {
          if (response.status == 200) {
            this.interviewAnalysisTypes = response.data.data;

            if (this.interviewAnalysisTypes.length !== 0) {
              this.pagination.page = page;
              this.pagination.totalPages = response.data.links.totalPages || 1;

              this.pagination.totalItems =
                response.data.links.totalItems ||
                this.pagination.totalPages * this.pagination.itemsPerPage;
            }
          } else {
            this.successfullInterviewAnalysisTypes = false;
            this.messageInterviewAnalysisTypes =
              "An error has occurred retrieving the list of interview analysis types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullInterviewAnalysisTypes = false;
          this.messageInterviewAnalysisTypes =
            "An error has occurred retrieving the list of interview analysis types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    handlePageChange(newPage) {
      console.log("handlePageChange: ", newPage);
      this.pagination.page = newPage;
      this.getAllInterviewAnalysisTypes(newPage);
    },
    observeMenuCreate() {
      const menu = this.$refs.menuContextsCreate;
      if (!menu) return;

      const observer = new MutationObserver(() => {
        this.$nextTick(() => {
          const dropdown = menu.$el.querySelector(".v-menu__content");

          if (dropdown && !this.isMenuOpenCreate) {
            console.log("Menú abierto");
            this.onOpenContextsCreate(true);
            this.isMenuOpenCreate = true; // Marca el menú como abierto
            dropdown.addEventListener("scroll", this.onScrollContextsCreate);
          } else if (!dropdown && this.isMenuOpenCreate) {
            console.log("Menú cerrado");
            this.onOpenContextsCreate(false);
            this.isMenuOpenCreate = false; // Marca el menú como cerrado
          }
        });
      });

      observer.observe(menu.$el, { childList: true, subtree: true });
    },
    getAllContextsCreate() {
      if (this.loadingContextsCreate) return;
      this.loadingContextsCreate = true;

      ContextsDataService.findAll(
        this.pageContextsCreate,
        this.itemsPerPageContextsCreate
      ).then(
        (response) => {
          if (response.status == 200) {
            console.log("Nuevos datos recibidos:", response.data.data);
            if (response.data.data.length === 0) {
              console.log("No hay más datos para cargar");
              this.loadingContextsCreate = false;
              return;
            }

            this.contextsCreate = [
              ...this.contextsCreate,
              ...response.data.data,
            ];

            this.totalItemsContextsCreate = response.data.links.totalItems;
            this.pageContextsCreate++;
          } else {
            this.successfullContexts = false;
            this.messageContexts =
              "An error has occurred retrieving the list of contexts. Please, try again later.";
          }

          this.loadingContextsCreate = false;
        },
        (error) => {
          this.successfullContexts = false;
          this.messageContexts =
            "An error has occurred retrieving the list of contexts. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loadingContextsCreate = false;
        }
      );
    },
    onOpenContextsCreate(isOpen) {
      console.log("Ejecutando onOpenContextsCreate, estado del menú:", isOpen);
      if (isOpen && this.contextsCreate.length === 0) {
        this.getAllContextsCreate();
      }
    },
    onScrollContextsCreate(event) {
      const dropdown = event.target;

      if (
        dropdown.scrollTop + dropdown.clientHeight >=
          dropdown.scrollHeight - 10 &&
        !this.loadingContextsCreate
      ) {
        console.log("Llamando a getAllContextsCreate()");
        this.getAllContextsCreate();
      }
    },
    getAllContextsEdit() {
      this.loading = true;

      ContextsDataService.findAll(-1, 0).then(
        (response) => {
          if (response.status == 200) {
            this.contextsEdit = response.data.data;
          } else {
            this.successfullContexts = false;
            this.messageContexts =
              "An error has occurred retrieving the list of contexts. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullContexts = false;
          this.messageContexts =
            "An error has occurred retrieving the list of contexts. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openCreateInterviewAnalysisType() {
      this.createInterviewAnalysisTypeDialog = true;

      this.$nextTick(() => {
        this.observeMenuCreate();
      });
    },
    createInterviewAnalysisType() {
      this.successfullCreateInterviewAnalysisType = true;
      this.messageCreateInterviewAnalysisType = "";

      if (this.$refs.createInterviewAnalysisTypeForm.validate()) {
        this.creatingInterviewAnalysisType = true;

        this.interviewAnalysisType.contextsList = [
          ...this.interviewAnalysisType.contexts,
        ];
        delete this.interviewAnalysisType.contexts;

        InterviewsDataService.createInterviewAnalysisType(
          this.interviewAnalysisType
        ).then(
          (response) => {
            if (response.status == 201) {
              this.getAllInterviewAnalysisTypes(1);

              this.messageCreateInterviewAnalysisType = "";
              this.successfullCreateInterviewAnalysisType = true;

              setTimeout(() => {
                this.messageCreateInterviewAnalysisType =
                  "Interview Analysis Type successfully created.";
              }, 10);
            } else {
              this.messageCreateInterviewAnalysisType = "";
              this.successfullCreateInterviewAnalysisType = true;

              setTimeout(() => {
                this.messageCreateInterviewAnalysisType =
                  "An error has occurred creating the interview analysis type. Please, try again later.";
              }, 10);
            }

            this.createInterviewAnalysisTypeDialog = false;
            this.creatingInterviewAnalysisType = false;
            this.interviewAnalysisType = {
              name: "",
              description: "",
              contexts: [],
            };
          },
          (error) => {
            this.messageCreateInterviewAnalysisTypeInside = "";
            this.messageCreateInterviewAnalysisType = "";

            if (error.response.status === 409) {
              this.successfullCreateInterviewAnalysisTypeInside = false;

              setTimeout(() => {
                this.messageCreateInterviewAnalysisTypeInside =
                  "Interview Analysis Type already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullCreateInterviewAnalysisType = false;

              setTimeout(() => {
                this.messageCreateInterviewAnalysisType =
                  "An error has occurred creating the interview analysis type. Please, try again later.";
              }, 10);

              this.createInterviewAnalysisTypeDialog = false;
              this.interviewAnalysisType = {
                name: "",
                description: "",
                contexts: [],
              };
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.creatingInterviewAnalysisType = false;
          }
        );
      }
    },
    cancelCreateInterviewAnalysisType() {
      this.createInterviewAnalysisTypeDialog = false;
      this.interviewAnalysisType = {
        name: "",
        description: "",
        contexts: [],
      };
    },
    openEditInterviewAnalysisType(interviewAnalysisType) {
      this.itemToEdit = { ...interviewAnalysisType };

      let itemContexts = [...interviewAnalysisType.contexts];

      this.itemToEdit.contexts = [];
      itemContexts.forEach((context) => {
        this.itemToEdit.contexts.push(context.id);
      });

      this.editInterviewAnalysisTypeDialog = true;
    },
    editInterviewAnalysisType() {
      this.successfullEditInterviewAnalysisType = true;

      if (this.$refs.editInterviewAnalysisTypeForm.validate()) {
        this.editingInterviewAnalysisType = true;

        this.itemToEdit.contextsList = [...this.itemToEdit.contexts];
        delete this.itemToEdit.contexts;

        InterviewsDataService.updateInterviewAnalysisType(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllInterviewAnalysisTypes(1);
              this.messageEditInterviewAnalysisType = "";
              this.successfullEditInterviewAnalysisType = true;

              setTimeout(() => {
                this.messageEditInterviewAnalysisType =
                  "Interview Analysis Type successfully updated.";
              }, 10);
            } else {
              this.messageEditInterviewAnalysisType = "";
              this.successfullEditInterviewAnalysisType = true;

              setTimeout(() => {
                this.messageEditInterviewAnalysisType =
                  "An error has occurred updating the interview analysis type. Please, try again later.";
              }, 10);
            }

            this.editInterviewAnalysisTypeDialog = false;
            this.editingInterviewAnalysisType = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditInterviewAnalysisTypeInside = "";
            this.messageEditInterviewAnalysisType = "";

            if (error.response.status === 409) {
              this.successfullEditInterviewAnalysisTypeInside = false;

              setTimeout(() => {
                this.messageEditInterviewAnalysisTypeInside =
                  "Interview Analysis Type already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditInterviewAnalysisType = false;

              setTimeout(() => {
                this.messageEditInterviewAnalysisType =
                  "An error has occurred updating the interview analysis type. Please, try again later.";
              }, 10);

              this.editInterviewAnalysisTypeDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingInterviewAnalysisType = false;
          }
        );
      }
    },
    openDeleteInterviewAnalysisType(interviewAnalysisType) {
      this.itemToDelete = { ...interviewAnalysisType };
      this.deleteInterviewAnalysisTypeDialog = true;
    },
    deleteInterviewAnalysisType() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingInterviewAnalysisType = true;

        InterviewsDataService.deleteInterviewAnalysisType(
          this.itemToDelete.id
        ).then(
          (response) => {
            if (response.status == 204) {
              this.getAllInterviewAnalysisTypes(1);
              this.messageDeleteInterviewAnalysisType = "";
              this.successfullDeleteInterviewAnalysisType = true;

              setTimeout(() => {
                this.messageDeleteInterviewAnalysisType =
                  "Interview Analysis Type successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteInterviewAnalysisType = "";
              this.successfullDeleteInterviewAnalysisType = false;

              setTimeout(() => {
                this.messageDeleteInterviewAnalysisType =
                  "An error has occurred deleting the interview analysis type. Please, try again later.";
              }, 10);
            }

            this.deleteInterviewAnalysisTypeDialog = false;
            this.deletingInterviewAnalysisType = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteInterviewAnalysisType = "";
            this.successfullDeleteInterviewAnalysisType = false;

            setTimeout(() => {
              this.messageDeleteInterviewAnalysisType =
                "An error has occurred deleting the interview analysis type. Please, try again later.";
            }, 10);

            this.deleteInterviewAnalysisTypeDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingInterviewAnalysisType = false;
          }
        );
      } else {
        this.messageDeleteInterviewAnalysisType = "";
        this.successfullDeleteInterviewAnalysisType = false;

        setTimeout(() => {
          this.messageDeleteInterviewAnalysisType =
            "An error has occurred deleting the interview analysis type. Please, try again later.";
        }, 10);

        this.deleteInterviewAnalysisTypeDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(interviewAnalysisType) {
      this.itemToShow = { ...interviewAnalysisType };
      this.showDetailsInterviewAnalysisTypeDialog = true;
    },
  },
};
</script>
